import { useState } from "react";

import styled from "styled-components";

// redux hooks 
import { useAppDispatch, useAppSelector } from '../../hooks';
import { setPopConfirmation } from '../../slices/miscSlice';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faExclamationCircle,  } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { Button } from "./FormElements";


const PopConfirmation: React.FC = () => {

    const dispatch = useAppDispatch();
    const { popConfirmation } = useAppSelector((state: any) => state.misc);
    const { okText, cancelText, show, alertType, message, okFunc, cancelFunc, input, inputPlaceholder, inputData } = popConfirmation;

    const [iconPop, setIconPop] = useState<any>(faCheckCircle);

    useEffect(() => {
        const iconTypes : Record<string, any> = {
            'success': faCheckCircle,
            'danger': faExclamationCircle,
            'warning': faExclamationCircle,
            'info': faExclamationCircle,
        }
        setIconPop(iconTypes[alertType]);
    }, [alertType]);

    const handleOk = () => {

        if (okFunc) okFunc();
        if (!input || !inputData) {
            dispatch(setPopConfirmation({show: false, message: '', alertType: 'info', input: null,inputPlaceholder: ''}));
        }
    }

    const handleCancel = () => {
        if (cancelFunc) cancelFunc();
        dispatch(setPopConfirmation({show: false, message: '', alertType: 'info', input: null,inputPlaceholder: '', inputData: null}));
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === '') return;
        dispatch(setPopConfirmation({...popConfirmation, input: e.target.value}));
    }

    return (
        show ?
         <PopConfirmationModal>
            <div className="pop-confirmation__window">
                <div className="pop-confirmation__content">
                    <div className={ "pop-confirmation__icon " + alertType} >
                        <FontAwesomeIcon icon={ iconPop }/>
                    </div>
                    <div className="pop-confirmation__message">
                        { message }
                    </div>
                  
                </div>
                {input ? <div className="pop_confirmation_input">
                    <label htmlFor="input">{inputPlaceholder}</label>
                    <input type="text" value={input} onChange={handleChange} onFocus={(e) => e.target.select()}  />
                    </div> : null}

                

                {
                /*const inputData = [{
            label: 'Proceso al que desea mover la orden:',
            name: 'process',
            type: 'select',
            options: processes.map((p : ProcessType) => ({value: p.id, label: p.process_name})),
            value: process.id
        }, 
        {
            label: 'Razón para mover la orden:',
            name: 'reason',
            type: 'text',
            value: ''
        }];*/
                
                inputData ? <div className="pop_confirmation_input">
                    {inputData.map((data: any, index: number) => {
                        if  (data.type === 'select') {
                            return (
                                <div key={index} className="pop_confirmarion_input_group">
                                    <label>{data.label}</label>
                                    <select value={data.value} onChange={(e) => dispatch(setPopConfirmation({...popConfirmation, inputData: inputData.map((d: any, i: number) => i === index ? {...d, value: e.target.value} : d)}))}>
                                        {data.options.map((option: any, index: number) => <option key={index} value={option.value}>{option.label}</option>)}
                                    </select>
                                </div>
                            )
                        }
                        if  (data.type === 'text') {
                            return (
                                <div key={index} className="pop_confirmarion_input_group">
                                    <label>{data.label}</label>
                                    <input type="text" value={data.value} onChange={(e) => dispatch(setPopConfirmation({...popConfirmation, inputData: inputData.map((d: any, i: number) => i === index ? {...d, value: e.target.value} : d)}))} />
                                </div>
                            )
                        }
                        return null;
                    }
                    )}
                </div> : null}


                <div className="pop-confirmation__buttons">
                    { !okText && !cancelText ? <Button onClick={ handleOk }>Aceptar</Button> : null }
                    { okText ? <Button onClick={handleOk}>{ okText }</Button> : null }
                    { cancelText ? <Button onClick={handleCancel}>{ cancelText }</Button> : null }
                    { !cancelFunc ? <Button onClick={ handleCancel }> {cancelText ? cancelText : "Cancelar" }</Button> : null }
                </div>
            </div>
        </PopConfirmationModal> 
        : null
    )

}

const PopConfirmationModal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9998;
    background-color: rgba(0,0,0,0.5);
    display:flex;
    justify-content: center;
    align-items: center;
    
    .pop-confirmation__window {
        width: 400px;
        background: #182f45;
        padding: 40px;
        border-radius: 5px;
    }
    
    .pop-confirmation__message {
        color: white;
        text-align: center;
        font-size: 1.2em;
        /* text-transform: capitalize; */
    }
    
    .pop-confirmation__content {
        display: flex;
        align-items: center;
    }
    
    .pop-confirmation__icon {
        margin-right: 15px;
        font-size: 3em;
    }

    .pop-confirmation__icon.danger{
        color: #d3767f;
    }
    .pop-confirmation__icon.success{
        color: #7fd3a3;
    }
    .pop-confirmation__icon.warning{
        color: #d3d17f;
    }
    .pop-confirmation__icon.info{
        color: #7f9fd3;
    }
`;

export default PopConfirmation;