import React, { useEffect, useState } from 'react';
import { useAppContext } from '../../Context'; // Ajusta el path según tu proyecto
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { ConsecutiveType, CustomerType, OrderType, SketchRequestLogType, SketchRequestType } from '../../types';
import { setCustomers } from '../../slices/customersSlice';
import { useGroups, usePermissions } from '../../services';
import {
  Autocomplete,
  Grid,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from '@mui/material';
import { Button, Label } from '../../components/shared/FormElements';
import { setSketch } from '../../slices/sketchesSlice';
import { compressImage } from '../../Utils';
import { setPopAlert, setPopConfirmation } from '../../slices/miscSlice';
import { constants } from '../../constants';
import { FixedSizeList } from 'react-window';

const PopupAttachAndUpload = ({
  open,
  onClose,
  endpoint,
  setCurrentSketch,
}: {
  open: boolean;
  onClose: () => void;
  endpoint: string;
  setCurrentSketch: Function;
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const { apiCall } = useAppContext();
  const dispatch = useAppDispatch();
  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setIsUploading(true);

      try {
        const blob: Blob = (await compressImage(file)) as Blob;

        // Crear un FormData para enviar el archivo
        const formData = new FormData();
        formData.append('file', blob, file.name);

        const options = {
          isFormData: true,
        };

        // Subir al servidor
        const response = await apiCall('PATCH', endpoint, formData, '', 'Error al procesar el Boceto', options);

        if (response) {
          dispatch(
            setPopAlert({ alertType: 'success', message: 'Boceto enviado correctamente a Validación', show: true })
          );
          dispatch(setSketch(response));
          setCurrentSketch(response);
        } else {
          dispatch(setPopAlert({ alertType: 'error', message: 'Error al enviar el boceto a validación', show: true }));
        }
      } catch (error) {
        dispatch(setPopAlert({ alertType: 'error', message: 'Error al enviar el boceto a validación', show: true }));
      } finally {
        setIsUploading(false);
        onClose();
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Adjuntar Boceto</DialogTitle>
      <DialogContent>
        {isUploading ? (
          <CircularProgress />
        ) : (
          <input
            type='file'
            accept='image/*'
            onChange={handleFileChange}
            style={{ display: 'block', margin: '1rem 0' }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isUploading} className='btn btn-secondary'>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const LoadFiles: React.FC<{ sketch_id: number; editMode: boolean }> = ({ sketch_id, editMode }) => {
  const { apiCall } = useAppContext();
  const [files, setFiles] = useState<string[]>([]);
  const [images, setImages] = useState<string[]>([]);
  const [downloaded, setDownloaded] = useState(false);
  const { isDiseñadordeBocetos } = useGroups(['Diseñador de Bocetos']);

  useEffect(() => {
    if (!sketch_id) return;

    const getFiles = async () => {
      const response = await apiCall(
        'GET',
        `sketch/${sketch_id}/attatchments/`,
        null,
        '',
        'Error al obtener los archivos adjuntos'
      );
      if (response) {
        if (response.zip_files && Array.isArray(response.zip_files)) {
          setFiles(response.zip_files);
        } else {
          if (response.images) {
            setImages(response.images);
          }
          setFiles([]);
        }
      }
    };

    getFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sketch_id]);

  if (!sketch_id) {
    return <p>{'No se han agregado adjuntos.'}</p>;
  }

  if (files.length === 0 && images.length === 0) {
    return <p>{'No se han agregado adjuntos o ya fueron descargados.'}</p>;
  }

  return (
    <div>
      <h3>Archivos Zip:</h3>
      {files.length === 0 ? <p>No se han agregado archivos zip.</p> : null}
      <div>
        {files.map((file, index) => (
          <div key={index}>
            {!downloaded ? (
              <a
                href={file}
                target='_blank'
                rel='noopener noreferrer'
                onClick={() => (isDiseñadordeBocetos ? setDownloaded(true) : null)}
              >
                Descargar archivo {index + 1}
              </a>
            ) : (
              'Descargado.'
            )}
          </div>
        ))}
      </div>

      <h3>Imágenes Adjuntas:</h3>

      {images.length > 0 ? (
        <div className='image-gallery'>
          {images.map((image, index) => (
            <div key={index}>
              <img src={image} alt={`imagen_${index}`} className='width-90' />
              <a href={image} download={`imagen_${index}.png`}>
                <Button className='btn btn-secondary' style={{ width: 200 }}>
                  Descargar Imagen {index + 1}
                </Button>
              </a>
            </div>
          ))}
        </div>
      ) : (
        <p>No se han agregado imágenes.</p>
      )}
    </div>
  );
};

export const LoadSketch: React.FC<{ sketch: SketchRequestType }> = ({ sketch }) => {
  const { apiCall } = useAppContext();
  const [image, setImage] = useState<string>('');

  useEffect(() => {
    if (!sketch) return;
    const getImages = async () => {
      const response = await apiCall('GET', `sketch/${sketch.id}/image/`, null, '', 'Error al obtener las imágenes');
      if (response) {
        if (response.length > 0) {
          setImage(response);
        }
      } else {
        setImage('');
      }
    };
    getImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sketch]);

  if (!sketch) {
    return <p>No se ha proporcionado un ID de boceto.</p>;
  }

  return (
    <div>
      {<h3 className='text-center'>Imagen del Boceto:</h3>}
      <div className='image-gallery text-center'>
        {image.length > 0 ? (
          <div className='text-center'>
            <img src={image} alt={`boceto`} className='width-90' />
            <a href={image} download={`boceto_${sketch.id}.png`}>
              <Button className='btn btn-secondary' style={{ width: 200 }}>
                Descargar Boceto
              </Button>
            </a>
          </div>
        ) : (
          <p>El diseñador no ha subido la imagen del boceto aún.</p>
        )}
      </div>
    </div>
  );
};

const SketchLog: React.FC<{ index: number; style: any; data: SketchRequestLogType[] }> = ({ index, style, data }) => {
  const log = data[index];
  return (
    <ListItem style={style} key={index}>
      <ListItemText
        primary={'Estado: ' + log.status}
        secondary={
          <>
            <span>{log.comment}</span>
            <br />
            <span>
              <b>Fecha:</b> {new Date(log.created_at).toLocaleString('es-CO')} - <b>Por:</b> {log.executed_by.username}
            </span>
          </>
        }
      />
    </ListItem>
  );
};

const RejectSketchDialog: React.FC<{ id: string; setCurrentSketch: Function; open: boolean; onClose: () => void }> = ({
  id,
  setCurrentSketch,
  open,
  onClose,
}) => {
  const { apiCall } = useAppContext();
  const dispatch = useAppDispatch();
  const [comment, setComment] = useState('');

  const handleReject = async () => {
    const data = { comment };
    const response = await apiCall('PATCH', `sketch/${id}/reject/`, data, '', 'Error al rechazar el boceto');
    if (response) {
      setComment('');
      setCurrentSketch(response);
      dispatch(setSketch(response));
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Solicitar Cambios en Boceto</DialogTitle>
      <DialogContent>
        <TextField
          multiline
          rows={4}
          value={comment}
          onChange={(event) => setComment(event.target.value)}
          label='Comentario'
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} className='btn btn-secondary'>
          Cancelar
        </Button>
        <Button onClick={handleReject} className='btn btn-secondary'>
          Solicitar Cambios
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CreateOrEditSketch: React.FC = () => {
  const { apiCall } = useAppContext();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [editMode, setEditMode] = useState(false);
  const [viewMode, setViewMode] = useState(false);
  const sketch: SketchRequestType = useAppSelector((state: any) => state.sketches.sketch);
  const customers: Array<CustomerType> = useAppSelector((state: any) => state.customer.customers);
  const [ordersByCustomer, setOrdersByCustomer] = useState<OrderType[]>([]);
  const [designers, setDesigners] = useState([]);
  const [currentSketch, setCurrentSketch] = useState<SketchRequestType>(sketch);
  const { canView_sketchrequests, canChange_sketchrequests, canAdd_sketchrequests } = usePermissions([
    'view_sketchrequests',
    'change_sketchrequests',
    'add_sketchrequests',
  ]);

  const { isDiseñadordeBocetos } = useGroups(['Diseñador de Bocetos']);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isRejectPopupOpen, setIsRejectPopupOpen] = useState(false);
  const [consecutives, setConsecutives] = useState<ConsecutiveType[]>([]);

  useEffect(() => {
    if (!canView_sketchrequests) {
      navigate('/');
      return;
    }

    if (!id || id === '0' || id === undefined) {
      if (!canAdd_sketchrequests) {
        navigate('/');
        return;
      }
      setEditMode(true);
      return;
    }

    setViewMode(true);

    getSketch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (customers.length === 0) {
      const getCustomers = async () => {
        const response = await apiCall('GET', 'customers/', null, '', 'Error al obtener los clientes');
        dispatch(setCustomers(response));
      };
      getCustomers();
    }

    if (designers.length === 0) {
      const getDesigners = async () => {
        const response = await apiCall(
          'GET',
          'users/group/Diseñador_de_Bocetos/',
          null,
          '',
          'Error al obtener los usuarios'
        );
        setDesigners(response);
      };
      getDesigners();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode]);

  const getSketch = async () => {
    const response = await apiCall('GET', `sketch/${id}/`, null, '', 'Error al obtener el boceto');
    if (response) {
      dispatch(setSketch(response));
      setCurrentSketch(response);
      if (response.customer) {
        getOrdersByCustomer(response.customer.id);
      }
    }
  };

  const getOrdersByCustomer = async (customer_id: number) => {
    const responseOrders = await apiCall(
      'GET',
      `orders/all/customer/${customer_id}/`,
      null,
      '',
      'Error al obtener las órdenes del cliente'
    );
    setOrdersByCustomer(responseOrders);
  };

  const getConsecutivesByOrder = async (order_id: number) => {
    const responseConsecutives = await apiCall(
      'GET',
      `order/${order_id}/consecutives/short/`,
      null,
      '',
      'Error al obtener los consecutivos de la orden'
    );
    setConsecutives(responseConsecutives);
  };

  useEffect(() => {
    if (currentSketch.order && currentSketch.order.id) {
      getConsecutivesByOrder(currentSketch.order.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSketch.order]);

  const handleCustomerSelected = async (event: any, value: any) => {
    if (value) {
      getOrdersByCustomer(value.id);
      setCurrentSketch({
        ...currentSketch,
        customer: {
          id: value.id,
          customer_name: value.customer_name,
          siigo_id: '',
          customer_city: {
            id: 0,
            city_name: '',
            state_name: '',
            country_name: '',
          },
        },
      });
    } else {
      // Opcionalmente, podrías restablecer customer a un valor inicial o mantener el estado anterior
      console.log('No se seleccionó ningún cliente');
    }
  };

  /*useEffect(() => {
    if (!currentSketch.attachments) return;
    const compress = async () => {
      dispatch(setLoading(true));
      const arrayBlobs: Blob[] = [];
      const promises = currentSketch.attachments
        ? Array.from(currentSketch.attachments).map(async (file: File) => {
            const blob: Blob = (await compressImage(file)) as Blob;
            arrayBlobs.push(blob);
          })
        : [];
      await Promise.all(promises);
      console.log('Blobs comprimidos:', arrayBlobs);
      setBlobsAttachments(arrayBlobs);
      dispatch(setLoading(false));
    };
    compress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSketch.attachments]); */

  const statuses = [
    { id: 'Creado', name: 'Creado' },
    { id: 'En Diseño', name: 'En Diseño' },
    { id: 'Por Validar', name: 'Por Validar' },
    { id: 'Aprobado', name: 'Aprobado' },
    { id: 'Cambios Solicitados', name: 'Cambios Solicitados' },
    { id: 'Asignado', name: 'Asignado' },
  ];

  const handleSave = async () => {
    if (!currentSketch.customer || !currentSketch.customer.id) {
      dispatch(setPopAlert({ alertType: 'warning', message: 'Por favor, selecciona un cliente', show: true }));
      return;
    }
    if (!id) {
      if (currentSketch.attachments === null || currentSketch?.attachments?.length === 0) {
        dispatch(
          setPopAlert({
            alertType: 'warning',
            message: 'Por favor, adjunta un zip con los archivos del boceto',
            show: true,
          })
        );
        return;
      }
    }

    const formData = new FormData();
    formData.append('customer', currentSketch.customer?.id ? currentSketch.customer.id.toString() : '');
    formData.append('designer', currentSketch.designer?.id ? currentSketch.designer.id.toString() : '');
    formData.append('status', currentSketch.status);
    formData.append('order', currentSketch.order?.id ? currentSketch.order.id.toString() : '');
    formData.append('consecutive', currentSketch.consecutive?.id ? currentSketch.consecutive.id.toString() : '');
    formData.append('observation', currentSketch.observation ?? '');
    if (currentSketch.attachments) {
      Array.from(currentSketch.attachments).forEach((file) => {
        formData.append('zip_file', file);
      });
    }

    const options = {
      isFormData: true,
    };

    if (!id) {
      const response = await apiCall('POST', 'sketches/', formData, '', 'Error al guardar el boceto', options);
      if (response) {
        setViewMode(true);
        setEditMode(false);
        setCurrentSketch(response);
        dispatch(setSketch(response));
        navigate('/boceto/' + response.id);
      }
    } else {
      const response = await apiCall('PUT', `sketch/${id}/`, formData, '', 'Error al guardar el boceto', options);
      if (response) {
        setViewMode(true);
        setEditMode(false);
        setCurrentSketch(response);
        dispatch(setSketch(response));
        navigate('/boceto/' + response.id);
      }
    }
  };

  const handleEditar = () => {
    setViewMode(false);
    setEditMode(true);
  };

  const handleValidateSketch = async () => {
    dispatch(
      setPopConfirmation({
        show: true,
        message: '¿Está seguro de aprobar el boceto?',
        alertType: 'danger',
        okFunc: () => {
          execValidation();
        },
      })
    );
  };

  const execValidation = async () => {
    const response = await apiCall('PATCH', `sketch/${id}/validate/`, null, '', 'Error al validar el boceto');
    if (response) {
      setCurrentSketch(response);
      dispatch(setSketch(response));
    }
  };

  const handleRejectSketch = async () => {
    setIsRejectPopupOpen(true);
  };

  const attach = () => {
    setIsPopupOpen(true);
  };

  return (
    <div className='container-wrap'>
      {id && (
        <PopupAttachAndUpload
          open={isPopupOpen}
          onClose={() => setIsPopupOpen(false)}
          endpoint={`sketch/${id}/send_to_validation/`}
          setCurrentSketch={setCurrentSketch}
        />
      )}
      {id && (
        <RejectSketchDialog
          open={isRejectPopupOpen}
          onClose={() => setIsRejectPopupOpen(false)}
          id={id}
          setCurrentSketch={setCurrentSketch}
        />
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={10} className='text-left'>
          <h2 className='text-left'>
            {viewMode || (editMode && sketch?.id) ? `Boceto No. ${sketch?.id}` : 'Nuevo Boceto'}
          </h2>
        </Grid>
        <Grid item xs={12} sm={2} className='text-right'>
          {canView_sketchrequests && (
            <>
              {sketch.status !== constants.sketchRequestStatus.APROBADO ? (
                <Button className='btn btn-primary' onClick={() => navigate('/bocetos/')}>
                  Ir a Bocetos
                </Button>
              ) : (
                <Button className='btn btn-primary' onClick={() => navigate('/bocetos/aprobados')}>
                  Ir a Bocetos Aprobados
                </Button>
              )}
            </>
          )}
        </Grid>
      </Grid>
      <div className='form-container'>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} className='text-left'>
            <div className='form-group'>
              <Label>Cliente</Label>
              {viewMode ? (
                <> {sketch?.customer?.customer_name} </>
              ) : (
                <Autocomplete
                  options={customers ? customers : []}
                  getOptionLabel={(option: CustomerType) => option.customer_name.toUpperCase()}
                  renderInput={(params) => (
                    <TextField className='form-control' {...params} label='Escribe el nombre del cliente...' />
                  )}
                  onChange={handleCustomerSelected}
                  value={
                    currentSketch && currentSketch.customer && currentSketch.customer.id !== null
                      ? currentSketch.customer
                      : null
                  }
                  isOptionEqualToValue={(option, value) => option.id === value.id} // Comparar usando el ID
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      <span>{option.customer_name}</span>
                    </li>
                  )}
                />
              )}
            </div>
            <div className='form-group'>
              <Label>Orden (no requerido) </Label>
              {viewMode ? (
                <> {sketch?.order?.id ?? 'No Asignada'} </>
              ) : (
                <>
                  {ordersByCustomer.length > 0 ? (
                    <Autocomplete
                      options={ordersByCustomer ? ordersByCustomer : []}
                      getOptionLabel={(option: OrderType) => (option.id ? option.id.toString() : '')}
                      renderInput={(params) => (
                        <TextField className='form-control' {...params} label='Escribe el número de la orden...' />
                      )}
                      onChange={(event: any, value: any) => {
                        setCurrentSketch({ ...currentSketch, order: value });
                      }}
                      value={
                        currentSketch && currentSketch.order && currentSketch.order.id !== null
                          ? (currentSketch.order as OrderType)
                          : null
                      }
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                          <span>{option.id}</span>
                        </li>
                      )}
                    />
                  ) : (
                    'No hay órdenes creadas para este cliente'
                  )}
                </>
              )}
            </div>
            {sketch.order && consecutives.length > 0 ? (
              <div className='form-group'>
                <Label>Consecutivo</Label>
                {viewMode ? (
                  <> {sketch?.consecutive?.consecutive_number ?? 'No Asignado'} </>
                ) : (
                  <Autocomplete
                    options={consecutives ? consecutives : []}
                    getOptionLabel={(option: ConsecutiveType) =>
                      option.consecutive_number ? option.consecutive_number.toString() : ''
                    }
                    renderInput={(params) => (
                      <TextField className='form-control' {...params} label='Escribe el consecutivo...' />
                    )}
                    onChange={(event: any, value: any) => {
                      setCurrentSketch({ ...currentSketch, consecutive: value });
                    }}
                    value={
                      currentSketch && currentSketch.order && currentSketch.order.id !== null
                        ? (currentSketch.consecutive as ConsecutiveType)
                        : null
                    }
                    isOptionEqualToValue={(option, value) => option === value}
                    renderOption={(props, option) => (
                      <li {...props} key={option.id}>
                        <span>{option.consecutive_number}</span>
                      </li>
                    )}
                  />
                )}
              </div>
            ) : null}
            <div className='form-group'>
              <Label>Asignar Diseñador</Label>
              {viewMode ? (
                <>
                  {' '}
                  {sketch?.designer?.first_name
                    ? sketch.designer.first_name + ' ' + sketch.designer.last_name
                    : 'No Asignado'}{' '}
                </>
              ) : (
                <Autocomplete
                  options={designers ? designers : []}
                  getOptionLabel={(option: any) => {
                    const frist_name = option.first_name ? option.first_name : '';
                    const last_name = option.last_name ? option.last_name : '';
                    return frist_name.toUpperCase() + ' ' + last_name.toUpperCase();
                  }}
                  renderInput={(params) => (
                    <TextField className='form-control' {...params} label='Escribe el nombre del diseñador...' />
                  )}
                  onChange={(event: any, value: any) => {
                    setCurrentSketch({ ...currentSketch, designer: value, status: value ? 'En Diseño' : 'Creado' });
                  }}
                  value={
                    currentSketch && currentSketch.designer && currentSketch.designer.id !== null
                      ? currentSketch.designer
                      : null
                  }
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      <span>{option.first_name?.toUpperCase() + ' ' + option.last_name?.toUpperCase()}</span>
                    </li>
                  )}
                />
              )}
            </div>
            <div className='form-group'>
              <Label>Estado</Label>
              {viewMode ? (
                <> {sketch?.status} </>
              ) : (
                <Select
                  className='form-control'
                  onChange={(event: any) => setCurrentSketch({ ...currentSketch, status: event.target.value })}
                  value={currentSketch.status ?? 'Creado'}
                >
                  {statuses.map((status) => (
                    <MenuItem key={status.id} value={status.id}>
                      {status.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </div>
            <div className='form-group'>
              <Label>Observaciones:</Label>
              {viewMode ? (
                <> {sketch?.observation ?? 'Sin Observaciones'} </>
              ) : (
                <TextField
                  className='form-control'
                  multiline
                  rows={4}
                  value={currentSketch.observation}
                  onChange={(event) => setCurrentSketch({ ...currentSketch, observation: event.target.value })}
                />
              )}
            </div>

            <div className='form-group'>
              {viewMode && (
                <>
                  {' '}
                  {sketch?.log && sketch.log.length > 0 ? (
                    <>
                      <Label>Registro</Label>
                      <FixedSizeList
                        height={400}
                        width={400}
                        itemSize={80}
                        itemCount={sketch.log.length}
                        itemData={sketch.log}
                      >
                        {SketchLog}
                      </FixedSizeList>
                    </>
                  ) : (
                    <>
                      <Label>Registro</Label>Sin registros.
                    </>
                  )}{' '}
                </>
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={6} className='text-left'>
            <div className='form-group'>
              <Label>Adjuntos</Label>
              {viewMode || (editMode && sketch?.id) ? (
                <>
                  <LoadFiles sketch_id={sketch ? sketch.id : 0} editMode={editMode} />
                  <br />
                </>
              ) : (
                <p>
                  {sketch?.status === constants.sketchRequestStatus.APROBADO
                    ? 'Los adjuntos fueron eliminados para liberar espacio porque el boceto ya ha sido aprobado.'
                    : 'No se han agregado adjuntos.'}
                </p>
              )}
              {editMode ? (
                <>
                  <b>Nuevo</b>
                  <input
                    type='file'
                    onChange={async (event) => {
                      if (event.target.files && event.target.files.length > 0) {
                        console.log('Adjuntos:', event.target.files);
                        setCurrentSketch({ ...currentSketch, attachments: event.target.files });
                      }
                    }}
                    accept='.zip, .rar'
                  />
                </>
              ) : null}
            </div>
          </Grid>
        </Grid>
      </div>
      {viewMode ? (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} className='text-right'>
            <span className='text-log'>
              <b>Última Modificación:</b> {new Date(sketch.updated_at).toLocaleString('es-CO')} -{' '}
              <b>Fecha de Creación:</b> {new Date(sketch.created_at).toLocaleString('es-CO')} - <b>Creado por:</b>{' '}
              {sketch.created_by.username}
            </span>
          </Grid>
        </Grid>
      ) : null}

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} className='text-right'>
          {!viewMode ? (
            <>
              {!isDiseñadordeBocetos ? (
                <Grid container spacing={2}>
                  <Grid item sm={6}>
                    <Button className='btn btn-primary' onClick={handleSave}>
                      Guardar
                    </Button>
                  </Grid>
                  <Grid item sm={6}>
                    <Button
                      className='btn btn-info'
                      onClick={() => {
                        setCurrentSketch(sketch);
                        setViewMode(true);
                        setEditMode(false);
                      }}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <Grid item sm={6}>
                  <Button className='btn btn-primary' onClick={attach}>
                    Adjuntar Boceto y enviar a Validar
                  </Button>
                </Grid>
              )}
            </>
          ) : canChange_sketchrequests ? (
            <Grid container spacing={2}>
              {!isDiseñadordeBocetos ? (
                <>
                  <Grid item sm={4}>
                    <Button className='btn btn-primary' onClick={handleEditar}>
                      Editar
                    </Button>
                  </Grid>
                  {sketch?.status === constants.sketchRequestStatus.POR_VALIDAR && (
                    <Grid item sm={4}>
                      <Button className='btn btn-info' onClick={handleValidateSketch}>
                        Validar
                      </Button>
                    </Grid>
                  )}
                  {sketch?.status === constants.sketchRequestStatus.POR_VALIDAR && (
                    <Grid item sm={4}>
                      <Button className='btn btn-info' onClick={handleRejectSketch}>
                        Rechazar
                      </Button>
                    </Grid>
                  )}
                </>
              ) : (
                <>
                  {(sketch?.status === constants.sketchRequestStatus.EN_DISENO ||
                    sketch?.status === constants.sketchRequestStatus.CAMBIOS_SOLICITADOS) && (
                    <Grid item sm={6}>
                      <Button className='btn btn-primary' onClick={attach}>
                        Adjuntar Boceto y enviar a Validar
                      </Button>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          ) : null}
        </Grid>
      </Grid>

      <div className='form-container'>
        {id && sketch?.status !== constants.sketchRequestStatus.CREADO && currentSketch.id && (
          <LoadSketch sketch={currentSketch} />
        )}
      </div>

      <br />
    </div>
  );
};
export default CreateOrEditSketch;
