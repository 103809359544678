import { FC, useEffect, useState } from 'react';
import {
  Grid,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Chip,
  Tooltip,
} from '@mui/material';

// types
import { ColumnsType, AttributesType } from '../../types';

//  style
import '../../styles/DataTable.css';

import { uCFirst } from '../../Utils';

// assets
import logo from '../../assets/images/logo-simbolo.png';
import Pagination from './Pagination';

const DataTable: React.FC<{
  data: any;
  columns: Array<ColumnsType>;
  actions?: Array<any> | null;
  groupedOptionBy?: string; // name of the field to group by
  totalEnabled?: string; // name of the field to get total
  filterByDateBy?: Array<AttributesType>; // name of the fields to filter by date
  selectItem?: (e: any, row: any) => void;
  itemsSelected?: Array<any>;
  hideConditions?: Array<any>;
}> = ({
  data,
  columns,
  actions,
  groupedOptionBy,
  totalEnabled,
  filterByDateBy,
  selectItem,
  itemsSelected,
  hideConditions,
}) => {
  const [dataRender, setDataRender] = useState(data);
  const [currentRows, setCurrentRows] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    rowsPerPage: 10,
  });

  const [sortValue, setSortValue] = useState('');
  const [sortTypeValue, setSortTypeValue] = useState('');

  const [groupedOptionByValue, setGroupedOptionByValue] = useState('');
  const [groupedArray, setGroupedArray] = useState([]);

  const [filterByDateByValue, setFilterByDateByValue] = useState('');

  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  const handleChangePage = (event: any, newPage: number) => {
    setPagination({
      ...pagination,
      page: newPage,
    });
  };

  const customStyle = {
    noImage: {
      filter: 'brightness(1) grayscale(1) invert(1) contrast(2)',
      display: 'block',
    },
    noImageText: {
      display: 'block',
      fontSize: '0.8rem',
      fontStyle: 'italic',
    },
  };

  const handleChangeRowsPerPage = (event: any) => {
    setPagination({
      ...pagination,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 1,
    });
  };

  useEffect(() => {
    const indexOfLastRow = pagination.page * pagination.rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - pagination.rowsPerPage;
    if (dataRender.length > 0) {
      setCurrentRows(dataRender.slice(indexOfFirstRow, indexOfLastRow));
    } else {
      setCurrentRows([]);
    }
  }, [pagination, dataRender]);

  const paginationNumbers = [];
  for (let i = 1; i <= Math.ceil(dataRender.length / pagination.rowsPerPage); i++) {
    paginationNumbers.push(i);
  }

  useEffect(() => {
    setPagination({
      ...pagination,
      page: 1,
    });
    setDataRender(data);

    if (groupedOptionBy) {
      const groupedArray: any = [];
      // get all grouped options unique array

      data.forEach((row: any) => {
        let value = row[groupedOptionBy];
        if (Array.isArray(value)) {
          value.forEach((val) => {
            if (!groupedArray.includes(val.name) && val.name !== null && val.name !== '' && val.name !== undefined) {
              groupedArray.push(val.name);
            }
          });
        } else {
          if (!groupedArray.includes(value) && value !== null) {
            groupedArray.push(value);
          }
        }
      });
      setGroupedArray(groupedArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (fromDate !== '' && toDate !== '' && filterByDateByValue !== '') {
      const dataFiltered = data.filter((row: any) => {
        if (filterByDateBy) {
          if (row[filterByDateByValue] >= fromDate && row[filterByDateByValue] <= toDate) {
            return row;
          }
        }

        return null;
      });
      setDataRender(dataFiltered);
    } else if (fromDate !== '' && filterByDateByValue !== '') {
      const dataFiltered = data.filter((row: any) => {
        if (filterByDateBy) {
          if (row[filterByDateByValue] === fromDate) {
            return row;
          }
        }

        return null;
      });
      setDataRender(dataFiltered);
    } else {
      setDataRender(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate, filterByDateByValue]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, filterByDateByValue]);

  const filterByKeyword = (e: any) => {
    const keyword = e.target.value;
    if (keyword === '') {
      setDataRender(data);
      return;
    }

    const dataFiltered = data.filter((row: any) => {
      let found = false;
      columns.forEach((column: ColumnsType) => {
        if (column.field) {
          if (valueForField(row, column.field)?.toString().toLowerCase().includes(keyword.trim().toLowerCase())) {
            found = true;
          }
        }
      });
      return found;
    });

    setDataRender(dataFiltered);
  };

  const handleChange = (e: any) => {
    if (e.target.name === 'sort') {
      setSortValue(e.target.value);
    } else {
      setSortTypeValue(e.target.value);
    }
  };

  useEffect(() => {
    if (sortValue !== '' && sortTypeValue !== '') {
      sortType();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortValue, sortTypeValue]);

  const sortType = () => {
    const arrayForSort = [...data];
    if (sortValue === '') {
      return;
    }
    const fieldParts = sortValue.split('.');
    const dataSorted = arrayForSort.sort((a, b) => {
      return interNalSort(a, b, sortTypeValue, sortValue, fieldParts);
    });
    setDataRender(dataSorted);
  };

  const interNalSort = (a: any, b: any, sortType: string, sort: string, fieldParts: any[]) => {
    let compareA: any = '';
    let compareB: any = '';
    if (fieldParts.length > 1) {
      let valueA = a;
      let valueB = b;
      for (const part of fieldParts) {
        if (valueA && typeof valueA === 'object' && part in valueA) {
          valueA = valueA[part];
          // return value only if is not an object
          if (typeof valueA !== 'object') {
            compareA = valueA;
          }
        } else {
          return 0;
        }
      }
      for (const part of fieldParts) {
        if (valueB && typeof valueB === 'object' && part in valueB) {
          valueB = valueB[part];
          // return value only if is not an object
          if (typeof valueB !== 'object') {
            compareB = valueB;
          }
        } else {
          return 0;
        }
      }
    } else {
      isNaN(a[sort]) ? (compareA = a[sort].toLowerCase()) : (compareA = parseInt(a[sort]));
      isNaN(b[sort]) ? (compareB = b[sort].toLowerCase()) : (compareB = parseInt(b[sort]));
    }
    if (sortType === 'asc') {
      if (compareA < compareB) {
        return -1;
      }
      if (compareA > compareB) {
        return 1;
      }
      return 0;
    } else {
      if (compareA > compareB) {
        return -1;
      }
      if (compareA < compareB) {
        return 1;
      }
      return 0;
    }
  };

  const setGroupedOptionBy = (e: any) => {
    setGroupedOptionByValue(e.target.value);

    if (e.target.value === '') {
      setDataRender(data);
      return;
    }

    const dataFiltered = data.filter((row: any) => {
      if (groupedOptionBy) {
        let value = row[groupedOptionBy];
        if (Array.isArray(value)) {
          let foundRow = value.find((val) => val.name === e.target.value);
          if (foundRow) {
            return row;
          }
        } else {
          if (value === e.target.value) {
            return row;
          }
        }
        return null;
      }

      return null;
    });
    setDataRender(dataFiltered);

    // go to first page
    setPagination({
      ...pagination,
      page: 1,
    });
  };

  const setFilterByDateBy = (e: any) => {
    setFilterByDateByValue(e.target.value);
  };

  const getTotalForColum = (column: any) => {
    let total = 0;
    data.forEach((row: any) => {
      total += parseInt(row[column]);
    });
    return total;
  };

  const ReplaceText = ({ conditions, field, row }: any) => {
    return conditions ? conditions.filter((condition: any) => condition.find === row[field])[0].replace : null;
  };

  const ArrayConvert: FC<{ data: any }> = ({ data }) => {
    let attrs: Array<AttributesType> = [];
    try {
      attrs = JSON.parse(data);
    } catch (error) {
      // check if data is a array of objects
      if (Array.isArray(data)) {
        attrs = data;
      } else {
        console.log(error);
      }
    }

    return (
      <ul style={{ padding: 0 }}>
        {attrs.map((attr: any, index) => {
          if (typeof attr === 'object' && attr.name && (attr.value || attr.id)) {
            if (attr.value) {
              return (
                <div key={index} className='array-item'>
                  <b>{attr.name}:</b> {uCFirst(attr.value)}
                  <br />
                </div>
              );
            } else if (attr.id) {
              return (
                <div key={index} className='array-item'>
                  {attr.name}
                  <br />
                </div>
              );
            } else {
              return (
                <div key={index} className='array-item'>
                  -
                </div>
              );
            }
          } else {
            return (
              <div key={index} className='array-item'>
                {attr}
              </div>
            );
          }
        })}
      </ul>
    );
  };

  const valueForField = (row: any, field: string) => {
    if (field) {
      const fieldParts = field.split('.');
      if (fieldParts.length > 1) {
        let value = row;
        for (const part of fieldParts) {
          if (value && typeof value === 'object' && part in value) {
            value = value[part];
            // return value only if is not an object
            if (typeof value !== 'object') {
              return value;
            }
          } else {
            return null;
          }
        }
      } else {
        return row[field];
      }
    }
    return null;
  };

  const ButtonTable: FC<{ action: any; row: any; index: number }> = ({ action, row, index }) => {
    if (action) {
      let actionIcon = action.icon;
      if (typeof actionIcon === 'object') {
        if (actionIcon.field && actionIcon.options) {
          const value = valueForField(row, actionIcon.field);
          actionIcon = actionIcon.options[value];
        }
      }

      let actionTootip = action.tooltip;
      if (typeof actionTootip === 'object') {
        if (actionTootip.field && actionTootip.options) {
          const value = valueForField(row, actionTootip.field);
          actionTootip = actionTootip.options[value];
        }
      }

      if (action.condition && action.condition.field && action.condition.value) {
        const value = valueForField(row, action.condition.field);
        if (value === action.condition.value) {
          return (
            <Tooltip key={index} title={actionTootip ? actionTootip : ''}>
              <button
                key={row + '-' + index}
                onClick={() => action.onClick(row)}
                className='btn btn-sm'
                disabled={action.disabled}
              >
                {actionIcon}
              </button>
            </Tooltip>
          );
        } else {
          return null;
        }
      } else {
        return (
          <Tooltip key={index} title={actionTootip ? actionTootip : ''}>
            <button
              key={row + '-' + index}
              onClick={() => action.onClick(row)}
              className='btn btn-sm'
              disabled={action.disabled}
            >
              {actionIcon}
            </button>
          </Tooltip>
        );
      }
    } else {
      return null;
    }
  };

  const ConditionalDecorator: FC<{ row: any; column: any }> = ({ row, column }) => {
    const val = valueForField(row, column.field);

    const decoratorObj = column.decorator;

    if (!val) {
      return <>-</>;
    }
    const condition = decoratorObj.value;
    if (decoratorObj.type === 'date') {
      const value = new Date(val);
      value.setHours(0, 0, 0, 0);
      /*const valuetoString = value.toLocaleDateString(
          'es-ES',
          {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }
        );*/

      // restar condition to value
      const diff = value.getTime() - condition.getTime();
      // get days
      const restFull = diff / (1000 * 60 * 60 * 24);
      // redondear
      const rest = Math.round(restFull);

      if (decoratorObj.operator === '<') {
        if (rest < decoratorObj.limit) {
          return <strong className={decoratorObj.class_decorator}>{val}</strong>;
        } else {
          return val;
        }
      } else if (decoratorObj.operator === '>') {
        if (rest > decoratorObj.limit) {
          return <strong className={decoratorObj.class_decorator}>{val}</strong>;
        } else {
          return val;
        }
      } else {
        return val;
      }
    } else if (decoratorObj.type === 'number') {
      if (decoratorObj.operator === '<') {
        if (val < condition) {
          return <strong className={decoratorObj.class_decorator}>{val}</strong>;
        } else {
          return val;
        }
      } else if (decoratorObj.operator === '>') {
        if (val > condition) {
          return <strong className={decoratorObj.class_decorator}>{val}</strong>;
        } else {
          return val;
        }
      }
    }

    return val;
  };

  return (
    <div className='table-responsive'>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <div className='search'>
            Buscar por palabra clave: <input type='text' onChange={(e) => filterByKeyword(e)} />
          </div>
        </Grid>
        <Grid item xs={12} sm={2}>
          {filterByDateBy && filterByDateBy.length > 0 ? (
            <div className='filter-by-date'>
              <FormControl fullWidth>
                <InputLabel id='filterByDateBy'>Filtrar por Fecha</InputLabel>
                <Select
                  labelId=''
                  label='Fecha de'
                  id='filterByDateBy'
                  name='filterByDateBy'
                  value={filterByDateByValue}
                  onChange={setFilterByDateBy}
                >
                  <MenuItem value={''}>Selecciona</MenuItem>
                  {filterByDateBy.map((field: AttributesType, index) => (
                    <MenuItem key={index} value={field.value}>
                      {field.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={3}>
          <div className='sort'>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id='sort'>Ordenar Por</InputLabel>
                  <Select
                    labelId='sort'
                    label='Ordenar Por'
                    id='sort'
                    name='sort'
                    value={sortValue}
                    onChange={handleChange}
                  >
                    <MenuItem value={''}>Selecciona</MenuItem>
                    {columns.map((column, index) => {
                      if (
                        column.type !== 'array' &&
                        column.type !== 'image' &&
                        column.type !== 'currency' &&
                        column.field
                      ) {
                        return (
                          <MenuItem key={index} value={column.field}>
                            {column.title}
                          </MenuItem>
                        );
                      }
                      return null;
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id='sortType'>Dirección</InputLabel>
                  <Select
                    labelId='sortType'
                    label='Tipo de Orden'
                    id='sortType'
                    name='sortType'
                    value={sortTypeValue}
                    onChange={handleChange}
                  >
                    <MenuItem value={''}>Selecciona</MenuItem>
                    <MenuItem value='asc'>Asc</MenuItem>
                    <MenuItem value='desc'>Desc</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} sm={2}>
          {groupedOptionBy && groupedArray.length > 0 ? (
            <div className='grouped-option'>
              <FormControl fullWidth>
                <InputLabel id='groupedOptionBy'>Agrupar por Categoría</InputLabel>
                <Select
                  labelId='groupedOptionBy'
                  label='Agrupar por'
                  id='groupedOptionBy'
                  name='groupedOptionBy'
                  value={groupedOptionByValue}
                  onChange={setGroupedOptionBy}
                >
                  <MenuItem value={''}>Selecciona</MenuItem>
                  {groupedArray.map((group, index) => (
                    <MenuItem key={index} value={group}>
                      {group}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={2}>
          <div className='rowNumber'>
            <label htmlFor='rowNumber' style={{ fontSize: 13 }}>
              Filas por página
            </label>
            <select name='rowNumber' id='rowNumber' value={pagination.rowsPerPage} onChange={handleChangeRowsPerPage}>
              <option value='10'>10</option>
              <option value='20'>20</option>
              <option value='30'>30</option>
              <option value='40'>40</option>
              <option value='50'>50</option>
            </select>
          </div>
        </Grid>
      </Grid>
      {filterByDateByValue && filterByDateByValue !== '' ? (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <div className='filter-date'>Selecciona el rango de fechas:</div>
          </Grid>
          <Grid item xs={12} sm={3}>
            <div className='filter-date'>
              Desde:
              <input type='date' onChange={(e) => setFromDate(e.target.value)} />
            </div>
          </Grid>
          <Grid item xs={12} sm={3}>
            <div className='filter-date'>
              Hasta: <input type='date' onChange={(e) => setToDate(e.target.value)} />
            </div>
          </Grid>
          <Grid item xs={12} sm={3}>
            <div className='filter-date'>
              <button
                className='btn btn-secondary'
                onClick={() => {
                  setFromDate('');
                  setToDate('');
                  setFilterByDateByValue('');
                }}
              >
                Limpiar
              </button>
            </div>
          </Grid>
        </Grid>
      ) : null}
      <TableContainer>
        <Table style={{ width: '100%' }}>
          <TableHead>
            <TableRow>
              {columns
                ? columns.map((column, index) => (
                    <TableCell key={index} className='text-center'>
                      {column.title}
                    </TableCell>
                  ))
                : null}
              {actions ? <TableCell className='text-center'>Acciones</TableCell> : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {currentRows && currentRows.length > 0 ? (
              currentRows.map((row, index) => {
                if (hideConditions) {
                  let hide = false;
                  for (const condition of hideConditions) {
                    if (row[condition.field] === condition.value) {
                      hide = true;
                    }
                  }
                  if (hide) {
                    return null;
                  }
                }
                return (
                  <TableRow key={index}>
                    {columns.map((column, index) => {
                      return (
                        <TableCell key={index} className={column.cell_class ? column.cell_class : ''}>
                          {column.type === 'array' && column.field ? (
                            <ArrayConvert data={valueForField(row, column.field)} />
                          ) : column.type === 'image' ? (
                            column.field &&
                            valueForField(row, column.field) &&
                            valueForField(row, column.field) !== '' ? (
                              <img
                                width={100}
                                src={process.env.REACT_APP_FILES_URL + valueForField(row, column.field)}
                                alt='Imagen de producto'
                              />
                            ) : (
                              <>
                                <img width={100} src={logo} alt='Sin Imagen' style={customStyle.noImage} />
                                <span style={customStyle.noImageText}>Sin Imagen</span>
                              </>
                            )
                          ) : column.type === 'currency' ? (
                            column.field ? (
                              <span>
                                {valueForField(row, column.field)} {row?.['currency']}
                              </span>
                            ) : null
                          ) : column.type === 'quantity' ? (
                            column.field ? (
                              <span>
                                {valueForField(row, column.field)}{' '}
                                {row?.['measure'] ? row?.['measure'] : column?.measure}
                              </span>
                            ) : null
                          ) : column.type === 'checkbox' ? (
                            column.field ? (
                              <input
                                type='checkbox'
                                checked={itemsSelected ? itemsSelected.find((item) => item.id === row['id']) : false}
                                onChange={selectItem ? (e) => selectItem(e, row) : void 0}
                              />
                            ) : null
                          ) : column.type === 'replace' ? (
                            column.field && column.conditions ? (
                              <ReplaceText conditions={column.conditions} field={column.field} row={row} />
                            ) : null
                          ) : column.type === 'datetime' ? (
                            column.field ? (
                              valueForField(row, column.field) ? (
                                new Date(valueForField(row, column.field)).toLocaleString('es-ES', {
                                  year: 'numeric',
                                  month: '2-digit',
                                  day: '2-digit',
                                  hour: '2-digit',
                                  minute: '2-digit',
                                  second: '2-digit',
                                  hour12: true,
                                })
                              ) : (
                                '-'
                              )
                            ) : null
                          ) : column.fields && column.type === 'concat' && column.fields.length > 0 ? (
                            column.fields
                              .map((field) => row[field])
                              .filter((field) => field)
                              .join(`${column.separator}`)
                          ) : column.field && column.type === 'decorator' && column.decorator ? (
                            <Chip
                              label={valueForField(row, column.field)}
                              color={
                                column.decorator[valueForField(row, column.field)]
                                  ? column.decorator[valueForField(row, column.field)]
                                  : 'secondary'
                              }
                            />
                          ) : column.field && column.type === 'decorator_bool' ? (
                            <Chip
                              label={valueForField(row, column.field) ? 'Activo' : 'Inactivo'}
                              color={valueForField(row, column.field) ? 'primary' : 'error'}
                            />
                          ) : column.field && column.type === 'conditional_decorator' ? (
                            <ConditionalDecorator row={row} column={column} />
                          ) : column.type === 'currencyformat' ? (
                            column.field && valueForField(row, column.field) ? (
                              <span>
                                {parseInt(valueForField(row, column.field)).toLocaleString('es-CO', {
                                  style: 'currency',
                                  currency: 'COP',
                                  minimumFractionDigits: 0,
                                })}
                              </span>
                            ) : (
                              '-'
                            )
                          ) : column.field ? (
                            valueForField(row, column.field) ? (
                              valueForField(row, column.field)
                            ) : (
                              '-'
                            )
                          ) : null}
                        </TableCell>
                      );
                    })}
                    {actions ? (
                      <TableCell>
                        <div className='actions-buttons'>
                          {actions.map((action, index) => {
                            return <ButtonTable action={action} row={row} key={index} index={index} />;
                          })}
                        </div>
                      </TableCell>
                    ) : null}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length + (actions ? 1 : 0)}>No hay datos</TableCell>
              </TableRow>
            )}
            {currentRows && currentRows.length > 0 && totalEnabled ? (
              <TableRow>
                <TableCell colSpan={columns.length} className='total'>
                  Cantidad Total: {getTotalForColum(totalEnabled)}
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination pagination={pagination} paginationNumbers={paginationNumbers} handleChangePage={handleChangePage} />
    </div>
  );
};

export default DataTable;
