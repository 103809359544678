import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { NullableCustomerType, SketchRequestType } from '../types';
import { initialStateOrders } from './ordersSlice';
import { initialStateUsers } from './usersSlice';

// Define a type for the slice state
interface SketchesState {
  sketches: Array<SketchRequestType>;
  sketch: SketchRequestType;
}

const intialStateCustomer: NullableCustomerType = {
  id: 0,
  customer_name: '',
  siigo_id: '',
  customer_city: {
    id: 0,
    city_name: '',
    state_name: '',
    country_name: '',
  },
};

// Define the initial state using that type
const initialState: SketchesState = {
  sketches: [],
  sketch: {
    id: 0,
    order: initialStateOrders.order,
    consecutive: initialStateOrders.consecutive,
    customer: intialStateCustomer,
    designer: initialStateUsers.user,
    status: 'Creado',
    log: [],
    is_deleted: false,
    created_at: '',
    updated_at: '',
    created_by: initialStateUsers.user,
  },
};

export const sketchesSlice = createSlice({
  name: 'sketches',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setSketches: (state, action: PayloadAction<SketchRequestType[]>) => {
      state.sketches = action.payload;
    },
    setSketch: (state, action: PayloadAction<SketchRequestType>) => {
      state.sketch = action.payload;
    },
  },
});

export const { setSketches, setSketch } = sketchesSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectSketch = (state: RootState) => state.sketch;
export const selectSketches = (state: RootState) => state.sketch.sketches;
export const initialStateSketch = initialState;
export default sketchesSlice.reducer;
