import { useState } from "react";
import { Grid, TableContainer, TableCell, TableRow, TableBody, TableHead, Table } from "@mui/material"
import { Button } from "../../components/shared/FormElements";
import { LineType } from "../../types";
import { useAppSelector, useAppDispatch } from "../../hooks";

import { setLoading } from "../../slices/miscSlice";
import { setLines } from "../../slices/linesSlice";
import { setPopAlert } from "../../slices/miscSlice";
import { useAppContext } from "../../Context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faForward, faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import '../../styles/DataTable.css';

const ViewLines: React.FC<{hideActions:boolean, handleAddToGroup?:Function, alreadySelected?:number[]}> = ({ hideActions, handleAddToGroup, alreadySelected }) => {
    const [addNewForm, setAddNewForm] = useState(false);
    const [editForm, setEditForm] = useState(false);
    const [newLine, setNewLine] = useState('' as string);
    const [editLine, setEditLine] = useState({} as LineType);
    const { lines } = useAppSelector(state => state.lines);
    const dispatch = useAppDispatch();

    const  {apiCall} = useAppContext();


    const handleSaveLine = async () => {
        if (newLine === '') {
            dispatch(setPopAlert({
                alertType: 'error',
                message: 'El nombre de la línea es requerido',
                show: true
            }));
            return;
        }
        dispatch(setLoading(true));
        const data = {
            line_name: newLine
        }
        const response = await apiCall('POST', 'line/', data, 'Línea creada exitosamente', 'Error al crear la línea');
        dispatch(setLoading(false));
        if (response.id) {
            dispatch(setLines([...lines, response]));
            setAddNewForm(false);
            setNewLine('');
        }else{
            dispatch(setPopAlert({
                alertType: 'error',
                message: 'Error al crear la línea',
                show: true
            }));
        }
    }

    const handleEditLine = (line:LineType) => {
        setEditForm(true);
        setEditLine(line);
    }

    const handleUpdateLine = async () => {
        if (editLine.line_name === '') {
            dispatch(setPopAlert({
                alertType: 'error',
                message: 'El nombre de la línea es requerido',
                show: true
            }));
            return;
        }
        dispatch(setLoading(true));
        const data = {
            line_name: editLine.line_name
        }
        const response = await apiCall('PUT', `line/${editLine.id}/`, data, 'Linea actualizada exitosamente', 'Error al actualizar la línea');
        dispatch(setLoading(false));
        if (response?.id) {
            const updatedLines = lines.map((line:LineType) => {
                if (line.id === response.id) {
                    return response;
                }
                return line;
            });
            dispatch(setLines(updatedLines));
            setEditForm(false);
            setEditLine({} as LineType);
        }else{
            dispatch(setPopAlert({
                alertType: 'error',
                message: 'Error al actualizar la línea',
                show: true
            }));
        }
    }

    const handleDeleteLine = async (id:number) => {
        dispatch(setLoading(true));
        const response = await apiCall('DELETE', `line/${id}/delete/`, null, 'Línea eliminada exitosamente', 'Error al eliminar la línea');
        dispatch(setLoading(false));
        if (response) {
            const updatedLines = lines.filter((line:LineType) => line.id !== id);
            dispatch(setLines(updatedLines));
        }
    }

    return <>
    <Grid container spacing={2}>
        <Grid item xs={12} sm={10} className="text-right">
            <h2>Lineas</h2>
        </Grid>
        <Grid item xs={12} sm={2} className="text-right">
        {!hideActions && <Button onClick={() => setAddNewForm(true)}>Agregar</Button>}
        </Grid>
    </Grid>
{addNewForm && <div>
    <h3>Agregar Nueva Linea</h3>
    <form>
        <input type="text" placeholder="Nombre de la linea" value={newLine} onChange={(e) => setNewLine(e.target.value)} />
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <Button onClick={() => setAddNewForm(false)}>Cancelar</Button>
            </Grid>
            <Grid item xs={6}>
                <Button onClick={handleSaveLine}>Guardar</Button>
            </Grid>
        </Grid>
    </form>
</div>}
{
    editForm && <div>
        <h3>Editar Linea</h3>
        <form>
            <input type="text" placeholder="Nombre de la linea" value={editLine.line_name} onChange={(e) => setEditLine({...editLine, line_name: e.target.value})} />
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Button onClick={() => setEditForm(false)}>Cancelar</Button>
                </Grid>
                <Grid item xs={6}>
                    <Button onClick={handleUpdateLine}>Guardar Cambios</Button>
                </Grid>
            </Grid>
        </form>
    </div>
}
{lines.length === 0 && <p>No hay líneas registradas</p>}
<Grid container spacing={2}>
    <div className="table-responsive">
   <TableContainer className="table-container">
         <Table>
              <TableHead>
                <TableRow>
                     <TableCell><b>NOMBRE</b></TableCell>
                     <TableCell
                        width={100}
                     ><b>ACCIONES</b></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                    {lines?.map((line : LineType) => (
                        <TableRow key={line.id}>
                            <TableCell>{line.line_name}</TableCell>
                            <TableCell>
                                <Grid container spacing={2}>
                                    {!hideActions ? <><Grid item xs={6}>
                                        <Button onClick={() => handleEditLine(line)}><FontAwesomeIcon icon={faPencil} /></Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button onClick={() => handleDeleteLine(line.id)}><FontAwesomeIcon icon={faTrash} /></Button>
                                    </Grid></> 
                                    :
                                    <Grid item xs={12}>
                                     {handleAddToGroup && <Grid item xs={6}>
                                        {alreadySelected && alreadySelected.includes(line.id)? 'Agregado' : <Button onClick={() => handleAddToGroup(line.id)}><FontAwesomeIcon icon={faForward} /></Button>}
                                     </Grid>}
                                </Grid> }
                                </Grid>
                            </TableCell>
                        </TableRow>
                        ))}
              </TableBody>
         </Table>
    </TableContainer>
    </div>
</Grid></>
}

export default ViewLines;