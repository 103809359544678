import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { UserType } from '../types';

export type FunctionType = {
  name: string;
  args: any;
  exec: boolean;
};

export type DesignerProbabilitiesType = {
  designer: UserType;
  probability: number;
};

// Define a type for the slice state
interface miscState {
  loading: boolean;
  popAlert: {
    alertType: string;
    message: string;
    show: boolean;
  };
  popConfirmation: {
    alertType: 'danger' | 'warning' | 'success' | 'info';
    message: string;
    show: boolean;
    size?: 'sm' | 'md' | 'lg';
    okText?: string;
    cancelText?: string;
    okFunc?: () => void;
    cancelFunc?: () => void;
    input?: string | null;
    inputPlaceholder?: string;
    inputData?: any;
  };
  designerProbabilities: DesignerProbabilitiesType[];
}

// Define the initial state using that type
const initialState: miscState = {
  loading: false,
  popAlert: {
    alertType: '',
    message: '',
    show: false,
  },
  popConfirmation: {
    alertType: 'info',
    message: '',
    show: false,
    input: null,
    inputPlaceholder: '',
  },
  designerProbabilities: [],
};

export const miscSlice = createSlice({
  name: 'miscs',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setPopAlert: (state, action: PayloadAction<miscState['popAlert']>) => {
      state.popAlert = action.payload;
    },
    setPopConfirmation: (state, action: PayloadAction<miscState['popConfirmation']>) => {
      state.popConfirmation = action.payload;
    },
    setDesignerProbabilities: (state, action: PayloadAction<DesignerProbabilitiesType[]>) => {
      state.designerProbabilities = action.payload;
    },
  },
});

export const { setLoading, setPopAlert, setPopConfirmation, setDesignerProbabilities } = miscSlice.actions;
// Other code such as selectors can use the imported `RootState` type
export const selectLoading = (state: RootState) => state.misc.loading;
export const selectPopAlert = (state: RootState) => state.misc.popAlert;
export const selectPopConfirmation = (state: RootState) => state.misc.popConfirmation;
