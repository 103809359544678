import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

interface ListsState {
    lists: List[];
    list : List;
}

export interface List{
    id: number;
    list_name: string;
}

const initialState: ListsState = {
    lists : [],
    list : {
        id: 0,
        list_name: "",
    }
}

export const listsSlice = createSlice({
    name: 'lists',
    initialState,
    reducers: {
        setLists: (state, action: PayloadAction<List[]>) => {
            state.lists = action.payload;
        },
        setList: (state, action: PayloadAction<List>) => {
            state.list = action.payload;
        }
    },
})

export const { setLists, setList } = listsSlice.actions

export const selectList = (state: RootState) => state.lists.list
export const selectLists = (state: RootState) => state.lists.lists

export default listsSlice.reducer