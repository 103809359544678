// capitalize first letter of a string
export const uCFirst = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const pictureValidator = (picture: any) => {
  picture = picture[0];
  // check if picture is a file
  if (picture instanceof File) {
    // check if picture is an image png, jpg, jpeg, gif
    if (
      picture.type === 'image/png' ||
      picture.type === 'image/jpg' ||
      picture.type === 'image/jpeg' ||
      picture.type === 'image/gif'
    ) {
      // check if picture is less than 1MB
      return {
        status: true,
        message: 'Imagen correcta',
      };
    } else {
      return {
        status: false,
        message: 'La imagen debe ser png, jpg, jpeg o gif',
      };
    }
  }
};

export const slugify = (string: string) => {
  // turn string into lowercase, remove spaces and replace them with dashes, remove accents, remove special characters, remove dots, remove commas
  return string
    .toLowerCase()
    .replace(/\s+/g, '-')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-zA-Z0-9-]/g, '')
    .replace(/\./g, '')
    .replace(/,/g, '');
};

export const checkImageExists = async (image_url: string) => {
  var http = new XMLHttpRequest();
  http.open('HEAD', image_url, false);
  if (http.status === 404) {
    return false;
  }
  http.send();
  return http.status === 200;
};

export const compressImage = async (file: any) => {
  const image = new Image();
  // check if file is a file or string fake path
  if (file instanceof File) {
    image.src = URL.createObjectURL(file);
  } else {
    image.src = file;
  }
  return new Promise((resolve) => {
    image.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      /* resize image to 1000px and conserve aspect ratio */
      const finalWidth = image.width > 1000 ? 1000 : image.width;
      canvas.width = finalWidth;
      canvas.height = image.height * (finalWidth / image.width);

      /* resize image to canvas if needed */
      ctx?.drawImage(image, 0, 0, finalWidth, image.height * (finalWidth / image.width));

      canvas.toBlob(
        (blob: any) => {
          resolve(blob);
        },
        'image/jpeg',
        0.5
      );
    };
  });
};

export const formatAmount = (amount: number) => {
  if (amount === null || amount === undefined) return '0,00';
  // Convertir el número a formato con separadores de miles
  const formattedAmount = amount
    .toString()
    .replace('.', ',')
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Reemplaza los separadores de miles con '

  return formattedAmount;
};

/*export const sortedCurrencyList = constants.CURRENCY_LIST.map((currency) => ({ ...currency })).sort((a, b) =>
a.name.localeCompare(b.name)
);*/

// Función para formatear el número con separadores de miles y decimales
export const formatNumber = (value: number | undefined) => {
  if (value === undefined) return '';
  return new Intl.NumberFormat('de-DE').format(value);
};

// Función para quitar el formato y mantener solo el número
export const parseNumber = (value: string) => {
  return parseFloat(value.replace(/\./g, '').replace(',', '.'));
};
