import { useState, useEffect} from "react";
import { useAppContext } from "../../Context";
import { useNavigate } from "react-router-dom";
import { useGroups } from "../../services";
import { Grid } from "@mui/material";
import Chart from "react-apexcharts";

type PieChartProps = {
    series: number[];
    labels: string[];
}

const ViewStats : React.FC = () => {


    const [ordersByLine, setOrdersByLine] = useState<PieChartProps>({ series: [], labels: [] });
    const [clothingByCity, setClothingByCity] = useState<PieChartProps>({ series: [], labels: [] });
    const [clothingByLine, setClothingByLine] = useState<PieChartProps>({ series: [], labels: [] });
    const [clothingFinishedByLine, setClothingFinishedByLine] = useState<PieChartProps>({ series: [], labels: [] });
    const [clothingByUser, setClothingByUser] = useState<PieChartProps>({ series: [], labels: [] });
    const [clothingByCustomer, setClothingByCustomer] = useState<PieChartProps>({ series: [], labels: [] });

    const { isAdministrador, isJefeDeVentas, isJefeDeProduccion } = useGroups(['Administrador', 'Jefe_De_Ventas', 'Jefe de Produccion']);

    const canView_stats = isAdministrador || isJefeDeVentas || isJefeDeProduccion;
    
    const { apiCall } = useAppContext();

    const navigate = useNavigate();

    useEffect(() => {

        if (!canView_stats){
            navigate('/home');
            return;
        }


        const getOrdersByLine = async () => {
            const response = await apiCall('GET', 'stats/', null , '', 'No se pudo obtener las estadisticas', true);
            if (response) {
               // check if exist ordersCountByLine in response
                if (response.ordersCountByLine) {
                    setOrdersByLine(organizeData(response.ordersCountByLine, 'line_name'));
                }

                if (response.clothingQuantityByLine) {
                    setClothingByLine(organizeData(response.clothingQuantityByLine, 'line_name'));
                }

                // clothingQuantityByCity
                if (response.clothingQuantityByCity) {
                    setClothingByCity(organizeData(response.clothingQuantityByCity, 'city_name'));
                }

                //clothingQuantityByLineFinished
                if (response.clothingQuantityByLineFinished) {
                    setClothingFinishedByLine(organizeData(response.clothingQuantityByLineFinished, 'line_name'));
                }

                // clothingQuantityByUser
                if (response.clothingQuantityByUser) {
                    setClothingByUser(organizeData(response.clothingQuantityByUser, 'author'));
                }

                // clothingQuantityByCustomer
                if (response.clothingQuantityByCustomer) {
                    setClothingByCustomer(organizeData(response.clothingQuantityByCustomer, 'customer'));
                }
            }
        }
        getOrdersByLine();
        // eslint-disable-next-line
    }, []);


    const organizeData = (data: any, statType: string) => {
        switch (statType) {
            case 'line_name':
                return {
                    series: data.map((item: any) => item.total),
                    labels: data.map((item: any) => item.line_name + ' (' + item.total + ')')
                }
            case 'city_name':
                return {
                    series: data.map((item: any) => item.total),
                    labels: data.map((item: any) => item.city_name + ' (' + item.total + ')')
                }
            case 'author':

                return {
                    series: data.map((item: any) => item.total),
                    labels: data.map((item: any) => shortLabelForNames(item.author) + ' (' + item.total + ')')
                }
            case 'customer':
                return {
                    series: data.map((item: any) => item.total),
                    labels: data.map((item: any) => shortLabelForNames(item.customer) + ' (' + item.total + ')')
                }
            default:
                return { series: [], labels: [] }
        }
    }

    const shortLabelForNames = (name: string) => {
        const newNameArr = name.split(' ');
        let newName = '';
        if (newNameArr.length === 0) {
            return name;
        }
        switch (newNameArr.length) {
            case 1:
                newName = newNameArr[0];
                break;
            case 2:
                newName = newNameArr[0] + ' ' + newNameArr[1];
                break;
            case 3:
                newName = newNameArr[0] + ' ' + newNameArr[2];
                break;
            default:
                newName = newNameArr[0] + ' ' + newNameArr[newNameArr.length - 2];
        }

        if (newName.length > 15) {
            return newName.substring(0, 18);
        }
        return newName;
    }

    return (
        <div className="container-wrap">
            <Grid container spacing={2}>
                <Grid item xs={12} sm={10} className="text-left">
                    <h2 className="text-left">Estadísticas</h2>
                </Grid>
                <Grid item xs={12} sm={2} className="text-right">
                    
                </Grid>
            </Grid> 
            <Grid container spacing={4}>
                <Grid item xs={12} sm={6} >
                    <div className="charts-container">
                        <h3>Órdenes por Línea</h3>
                        <Chart
                            options={{labels: ordersByLine.labels, legend: {show: true}}}
                            series={ordersByLine.series}
                            type="donut"
                            width="150%"
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} >
                    <div className="charts-container">
                        <h3>Prendas por Línea</h3>
                        <Chart
                            options={{labels: clothingByLine.labels, legend: {show: true}}}
                            series={clothingByLine.series}
                            type="donut"
                            width="150%"
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} >
                    <div className="charts-container">
                        <h3>Prendas Terminadas por Línea</h3>
                        <Chart
                            options={{labels: clothingFinishedByLine.labels, legend: {show: true}}}
                            series={clothingFinishedByLine.series}
                            type="donut"
                            width="150%"
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <div className="charts-container">
                        <h3>Prendas por Creador de la Orden</h3>
                        <Chart
                            options={{labels: clothingByUser.labels, legend: {show: true}}}
                            series={clothingByUser.series}
                            type="donut"
                            width="150%"
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <div className="charts-container">
                        <h3>Prendas por Cliente</h3>
                        <Chart
                            options={{labels: clothingByCustomer.labels, legend: {show: true}}}
                            series={clothingByCustomer.series}
                            type="donut"
                            width="150%"
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <div className="charts-container">
                        <h3>Prendas por Ciudad</h3>
                        <Chart
                            options={{labels: clothingByCity.labels, legend: {show: true}}}
                            series={clothingByCity.series}
                            type="donut"
                            width="150%"
                        />
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default ViewStats;