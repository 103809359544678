import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'
import { Line } from './linesSlice';

interface GroupsOTState {
    groupsOT: GroupOT[];
    groupOT : GroupOT;
}

export interface GroupOT{
    id: number;
    group_name: string;
    lines: Line[];
}

const initialState: GroupsOTState = {
    groupsOT : [],
    groupOT : {
        id: 0,
        group_name: "",
        lines: [],
    }
}

export const groupsOTSlice = createSlice({
    name: 'groupsOT',
    initialState,
    reducers: {
        setGroupsOT: (state, action: PayloadAction<GroupOT[]>) => {
            state.groupsOT = action.payload;
        },
        setGroupOT: (state, action: PayloadAction<GroupOT>) => {
            state.groupOT = action.payload;
        }
    },
})

export const { setGroupsOT, setGroupOT } = groupsOTSlice.actions

export const selectGroupOT = (state: RootState) => state.groupsOT.line
export const selectGroupsOT = (state: RootState) => state.groupsOT.lines

export default groupsOTSlice.reducer