import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { setProcess } from "../../slices/processSlice";
import { setOrder } from "../../slices/ordersSlice";
import { useAppSelector, useAppDispatch } from "../../hooks";

import { useAppContext } from "../../Context";

import { LoaderTemporal } from "../../components/shared/Loader";
import DataTable from "../../components/shared/DataTable";


import { Grid } from "@mui/material";

import { ColumnsType, OrderProcessType, ProcessType } from "../../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faCheck, faEye, faForward, faPlay, faRoute } from "@fortawesome/free-solid-svg-icons";
import { Button } from "../../components/shared/FormElements";
import {  setLoading, setPopAlert, setPopConfirmation } from "../../slices/miscSlice";

import { usePermissions } from "../../services";
import { constants } from "../../constants";
import { store } from "../../store";

const ViewOrdersProcess : React.FC = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { apiCall } = useAppContext();
    const dispatch = useAppDispatch();
    const process : ProcessType = useAppSelector(state => state.process.process);
    const processes : Array<ProcessType> = useAppSelector(state => state.process.processes);

    const { canChange_orderprocess, canView_orderprocess, canAdd_orderprocess } = usePermissions(['change_orderprocess', 'view_orderprocess', 'add_orderprocess']);

    const [ordersProcess, setOrdersProcess] = useState<Array<OrderProcessType>>([]);

    const getProcess = async () => {
        dispatch(setLoading (true));    
        const response = await apiCall('GET', `process/${id}/`, null, '', 'No se pudo obtener el proceso', true);
        dispatch(setLoading (false));
        if (response) {
            setProcess(response);
        }
    }

    const getOrdersProcess = async () => {
        const response = await apiCall('GET', `order_process/process/${id}/`, null, '', 'No se pudo obtener las ordenes del proceso', true);
        if (response) {
            setOrdersProcess(response);
        }else{
            setOrdersProcess([]);
        }
    }

    useEffect(() => {

        if (!canView_orderprocess) {
            navigate('/');
            return
        }

        if (!id){
            navigate('/procesos');
            return
        }

        if (processes.length > 0) {
            const process = processes.find((process: ProcessType) => process.id === parseInt(id));
            if (process) {
                dispatch(setProcess(process));
            }
        }

        if (!process) {
            getProcess();
        }

        // get all orders process for process.id 
      
        getOrdersProcess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    if (!process) {
        return <LoaderTemporal />;
    }

    const hoyOnlyDate = new Date();
    hoyOnlyDate.setHours(0,0,0,0);

    const conditionDecorator = { 
        operator: '<', 
        value: hoyOnlyDate, 
        class_decorator : 'text-danger', 
        type:'date',
        limit : constants.settings.limitDateDays
    } 


    const columns : Array<ColumnsType> = [
        {title: 'Orden', field: 'order.id'},
        {title: 'Cliente', field: 'order.customer.customer_name'},
        {title: 'Línea', field: 'order.line.line_name'},
        {title: 'Fecha de Orden', field: 'order.order_date'},
        {title: 'Cons', field: 'order.consecutives_count', cell_class : 'text-center'},
        {title: 'Fin de Producción', field: 'order.production_date_end', type: 'conditional_decorator', decorator: conditionDecorator, cell_class : 'text-center'},
        {title: 'Entrega', field: 'order.delivery_date', type: 'conditional_decorator', decorator: conditionDecorator , cell_class : 'text-center'},
        {title: 'Inicio ' + process.process_name , field: 'created_at', type: 'datetime'},
        {title: 'Estado', field: 'process_status', type: 'decorator', decorator : { "En Proceso" : "primary", "Finalizado": "success", "Bloqueado": "warning", "Creada": "info", }},
    ]; 
    
    const handleStartOrderProcess = (orderProcess : OrderProcessType) => {
        dispatch(setPopConfirmation({show: true, message: '¿Está seguro que desea comenzar este proceso?', alertType: 'danger', okFunc: () => startOrderProcess(orderProcess.id) }));
    }

    const startOrderProcess = async (processOrder_id : number) => {
        dispatch(setLoading (true));
        const data = {process_status: constants.processStatus.EN_PROCESO};
        const response = await apiCall('PUT', `order_process/${processOrder_id}/change/`, data, 'Proceso Iniciado', 'Error al iniciar el proceso', true);
        dispatch(setLoading (false));
        if (response) {
            const newOrdersProcess = ordersProcess.map((op : OrderProcessType) => op.id === processOrder_id  ? {...op, process_status: constants.processStatus.EN_PROCESO} : op);
            setOrdersProcess(newOrdersProcess);
        }
    }

    const handleFinishOrderProcess = (orderProcess : OrderProcessType) => {
        dispatch(setPopConfirmation({show: true, message: '¿Está seguro que desea finalizar este proceso?', alertType: 'danger', okFunc: () => finishOrderProcess(orderProcess.id) }));
    }
       
    const finishOrderProcess = async (processOrder_id  : number) => {
        dispatch(setLoading (true));
        const data = {process_status: constants.processStatus.FINALIZADO};
        const response = await apiCall('PUT', `order_process/${processOrder_id }/change/`, data, 'Proceso finalizado', 'Error al finalizar el proceso', true);
        dispatch(setLoading (false));
        if (response) {
            const newOrdersProcess = ordersProcess.map((op : OrderProcessType) => op.id === processOrder_id  ? {...op, process_status: constants.processStatus.FINALIZADO} : op);
            setOrdersProcess(newOrdersProcess);
            setTimeout(() => {
                getOrdersProcess();
            },2000);
        }
    }

    const handleBlockOrderProcess = async (processOrder : any) => {
        dispatch(setPopConfirmation({show: true, message: '¿Está seguro que desea bloquear o pausar este proceso?', alertType: 'danger', okFunc: () => blockOrderProcess(processOrder.id), input: ' ', inputPlaceholder: 'Escriba una razón para bloquear o pausar el proceso: ' }));
    }
    
    const blockOrderProcess = async (processOrder_id  : number) => {
        const ppconf = store.getState().misc.popConfirmation;
        if (ppconf.input === "" || ppconf.input === undefined || ppconf.input === null || ppconf.input === " ") {
            dispatch(setPopAlert({message: 'Debe escribir una razón para bloquear o pausar el proceso', show: true, alertType: 'danger'}));
            return;
        }

        dispatch(setLoading (true));
        const data = {process_status: constants.processStatus.BLOQUEADO, process_comment: ppconf.input};
        const response = await apiCall('PUT', `order_process/${processOrder_id }/change/`, data, 'Proceso bloqueado o pausado', 'Error al bloquear o pausar el proceso', true);
        dispatch(setLoading (false));
        if (response) {
            dispatch(setPopConfirmation({show: false, message: '', alertType: 'info', input:null}));
            const newOrdersProcess = ordersProcess.map((op : OrderProcessType) => op.id === processOrder_id  ? {...op, process_status: constants.processStatus.BLOQUEADO} : op);
            setOrdersProcess(newOrdersProcess);
        }
    }

    const handleResumeOrderProcess = async (processOrder : any) => {
        dispatch(setPopConfirmation({show: true, message: '¿Está seguro que desea reanudar este proceso?', alertType: 'danger', okFunc: () => resumeOrderProcess(processOrder.id) }));
    }

    const resumeOrderProcess = async (processOrder_id  : number) => {
        dispatch(setLoading (true));
        const data = {process_status: constants.processStatus.EN_PROCESO};
        const response = await apiCall('PUT', `order_process/${processOrder_id }/change/`, data, 'Proceso reanudado', 'Error al reanudar el proceso', true);
        dispatch(setLoading (false));
        if (response) {
            const newOrdersProcess = ordersProcess.map((op : OrderProcessType) => op.id === processOrder_id  ? {...op, process_status: constants.processStatus.EN_PROCESO} : op);
            setOrdersProcess(newOrdersProcess);
        }
    }

    const handleViewOrder = (orderProcess : any) => {
        dispatch(setOrder(orderProcess.order));
        navigate(`/orden/${orderProcess.order.id}/detalle`);
    }

    const handleMoveToProcess = async (processOrder : any) => {
        const inputData = [{
            label: 'Proceso al que desea mover la orden:',
            name: 'process',
            type: 'select',
            options: processes.map((p : ProcessType) => ({value: p.id, label: p.process_name})),
            value: process.id
        }, 
        {
            label: 'Razón para mover la orden:',
            name: 'reason',
            type: 'text',
            value: ''
        }];

        dispatch(setPopConfirmation({show: true, message: '¿Está seguro que desea mover este proceso?', alertType: 'danger', okFunc: () => moveOrderProcess(processOrder.id), inputData: inputData, inputPlaceholder: '' }));

    }

    const moveOrderProcess = async (processOrder_id : number) => {
        const ppconf = store.getState().misc.popConfirmation;
        if (ppconf.inputData[0].value === "" || ppconf.inputData[0].value === undefined || ppconf.inputData[0].value === null || ppconf.inputData[0].value === " ") {
            dispatch(setPopAlert({message: 'Debe seleccionar un proceso al que mover la orden', show: true, alertType: 'danger'}));
            return;
        }

        if (ppconf.inputData[1].value === "" || ppconf.inputData[1].value === undefined || ppconf.inputData[1].value === null || ppconf.inputData[1].value === " ") {
            dispatch(setPopAlert({message: 'Debe escribir una razón para mover la orden', show: true, alertType: 'danger'}));
            return;
        }

        if (ppconf.inputData[0].value === process.id) {
            dispatch(setPopAlert({message: 'La orden ya se encuentra en este proceso', show: true, alertType: 'info'}));
            return;
        }

        dispatch(setLoading (true));
        const data = {move_to: ppconf.inputData[0].value, reason: ppconf.inputData[1].value};
        const response = await apiCall('PATCH', `order_process/${processOrder_id }/move/`, data, 'Proceso movido', 'Error al mover el proceso', true);
        dispatch(setLoading (false));
        if (response) {
            dispatch(setPopConfirmation({show: false, message: '', alertType: 'info', input:null}));
            const newOrdersProcess = ordersProcess.filter((op : OrderProcessType) => op.id !== processOrder_id);
            setOrdersProcess(newOrdersProcess);
        }
    }



    const actions = [
        {icon: <FontAwesomeIcon icon={faEye} />, tooltip: 'Ver', onClick: handleViewOrder},
        canChange_orderprocess ?{icon: <FontAwesomeIcon icon={faPlay} />, tooltip: 'Iniciar', onClick: handleStartOrderProcess, condition:{field: 'process_status', value:constants.processStatus.POR_INICIAR}} : null,
        {icon: <FontAwesomeIcon icon={faCheck} />, tooltip: 'Finalizar', onClick: handleFinishOrderProcess, condition:{field: 'process_status', value: constants.processStatus.EN_PROCESO}},
        {icon: <FontAwesomeIcon icon={faBan} />, tooltip: 'Bloquear/Pausar', onClick: handleBlockOrderProcess, condition:{field: 'process_status', value: constants.processStatus.EN_PROCESO}},
        {icon: <FontAwesomeIcon icon={faForward} />, tooltip: 'Reanudar', onClick : handleResumeOrderProcess, condition:{field: 'process_status', value: constants.processStatus.BLOQUEADO}},
        canAdd_orderprocess ? {icon: <FontAwesomeIcon icon={faRoute} />, tooltip: 'Mover a otro proceso', onClick: handleMoveToProcess} : null
    ];
  
    return <div className="container-wrap">
    <Grid container spacing={2}>
        <Grid item xs={12} sm={10} className="text-left">
            <h2 className="text-left">{process.process_name}</h2>
        </Grid>
        <Grid item xs={12} sm={2} className="text-right">
            <Button className="btn btn-primary" onClick={() => navigate('/procesos/')}>Volver</Button>
        </Grid>
    </Grid> 
    <Grid container spacing={2}>
        <Grid item xs={12} key={"process"} className="text-left">
            <div className="process-orders-wrap">
                <DataTable data={ordersProcess} columns={columns} actions={actions}  />
            </div>
        </Grid>
    </Grid>
</div>;
}

export default ViewOrdersProcess;