import { useAppSelector } from '../../hooks';
import styled from 'styled-components';

import logoSimbolo from '../../assets/images/logo-simbolo.png';

const Loader : React.FC = () => {
    const loading = useAppSelector(state => state.misc.loading);
    return loading ? <LoaderStyled>
    <img src={logoSimbolo} alt="Loading..." />
</LoaderStyled> : null;
}

const LoaderTemporal : React.FC = () => {
    return <LoaderStyled>
        <img src={logoSimbolo} alt="Loading..." />
    </LoaderStyled>
}

const LoaderStyled = styled.div`
display: flex;
justify-content: center;
align-items: center;
height: 100vh;
background-color: #1a1a1a;
left: 0;
top: 0;
position: fixed;
width: 100%;
z-index: 9999;

img {
    width: 100px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotateY(0deg); }
    100% { transform: rotateY(360deg); }
  }


`;
export { Loader, LoaderTemporal };