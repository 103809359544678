import { useState, useEffect } from 'react';

// router
import { useNavigate } from 'react-router-dom';

// hooks redux
import { useAppDispatch } from '../../hooks';
import { setPopAlert } from '../../slices/miscSlice';

//  MUI
import { Grid } from '@mui/material';

// components
import { TextField, Button, Label } from '../../components/shared/FormElements';

// context
import { useAppContext } from '../../Context';

// assets
import logoSimbolo from '../../assets/images/logo-simbolo.png';

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const { apiCall, setAuth, logout, setOpen } = useAppContext();
  const navigate = useNavigate();

  const [dataUser, setDataUser] = useState({
    username: '',
    password: '',
  });

  const verifyAuth = async () => {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');

    if (token && user) {
      const data = {
        id: JSON.parse(user).id,
      };
      const resp = await apiCall('POST', 'verify-token/', data, '', 'Error al iniciar sesión');
      if (resp) {
        localStorage.setItem('user', JSON.stringify(resp.user));
        const auth = {
          token: token,
          user: {
            id: resp.user.id,
            name: resp.user.name,
            username: resp.user.name,
            email: resp.user.email,
            status: resp.user.status,
            groups: resp.user.groups,
            permissions: resp.user.permissions,
          },
          isLogged: true,
        };
        setAuth(auth);
        setOpen(false);
        // reenviar a la url actual
      } else {
        logout();
      }
    }
  };

  useEffect(
    () => {
      verifyAuth();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleLogin = async () => {
    if (dataUser.username.length === 0) {
      dispatch(
        setPopAlert({
          message: 'El campo usuario es requerido',
          show: true,
          alertType: 'danger',
        })
      );
      return;
    }

    if (dataUser.password.length === 0) {
      dispatch(
        setPopAlert({
          message: 'El campo contraseña es requerido',
          show: true,
          alertType: 'danger',
        })
      );
      return;
    }

    const resp = await apiCall('POST', 'login/', dataUser, '', 'Error al iniciar sesión');
    if (resp?.access_token) {
      localStorage.setItem('token', resp.access_token);
      localStorage.setItem('user', JSON.stringify(resp.user));

      const auth = {
        token: resp.access_token,
        user: {
          id: resp.user.id,
          username: resp.user.username,
          name: resp.user.name,
          email: resp.user.email,
          status: resp.user.status,
          groups: resp.user.groups,
          permissions: resp.user.permissions,
        },
        isLogged: true,
      };

      setAuth(auth);
      navigate('/');
    }
  };

  return (
    <Grid container className='userforms'>
      <Grid item xs={12} sm={12} md={12}>
        <img src={logoSimbolo} alt='logo' className='App-logo' />
        <h2>Inicio de Sesión</h2>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <form>
          <div className='form-group'>
            <Label htmlFor='username'>Usuario</Label>
            <TextField
              type='text'
              name='username'
              id='username'
              className='input'
              onChange={(e: { target: { value: string } }) =>
                setDataUser({
                  ...dataUser,
                  username: e.target?.value as string,
                })
              }
            />
          </div>
          <div className='form-group'>
            <Label htmlFor='password'>Contraseña</Label>
            <TextField
              type='password'
              name='password'
              className='input'
              id='password'
              onChange={(e: { target: { value: string } }) =>
                setDataUser({
                  ...dataUser,
                  password: e.target?.value as string,
                })
              }
            />
          </div>
          <div className='form-group'>
            <Button type='button' className='button' onClick={handleLogin}>
              Iniciar Sesión
            </Button>
          </div>
        </form>
      </Grid>
    </Grid>
  );
};

export default Login;
