import { useState, useEffect } from 'react';
import { Grid, TableContainer, TableCell, TableRow, TableBody, TableHead, Table } from '@mui/material';
import { Button } from '../../components/shared/FormElements';
import { GroupOTType, LineType } from '../../types';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { setLines } from '../../slices/linesSlice';
import { setLoading } from '../../slices/miscSlice';
import { setPopAlert } from '../../slices/miscSlice';
import { useAppContext } from '../../Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import '../../styles/DataTable.css';
import { useNavigate, useParams } from 'react-router-dom';
import ViewLines from './ViewLines';

const ViewGroupsOTLines: React.FC = () => {
  const [alreadySelected, setAlreadySelected] = useState([]);
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [currentGroupOT, setCurrentGroupOT] = useState({} as GroupOTType);
  const { lines } = useAppSelector((state) => state.lines);
  const { apiCall } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    const getLines = async () => {
      dispatch(setLoading(true));
      const response = await apiCall('GET', 'lines/', null, '', 'Error al obtener las líneas');
      dispatch(setLoading(false));
      dispatch(setLines(response));
    };
    getLines();

    const getCurrentGroup = async () => {
      dispatch(setLoading(true));
      const response = await apiCall(
        'GET',
        `groups-ot/${id}/`,
        null,
        '',
        'Error al obtener los datos del grupo de orden de trabajo'
      );
      dispatch(setLoading(false));
      // get id for lines on  response.lines
      if (response.lines) {
        const lineIds = response.lines.map((line: LineType) => line.id);
        setAlreadySelected(lineIds);
      }
      setCurrentGroupOT(response);
    };
    getCurrentGroup();

    // eslint-disable-next-line
  }, []);

  const handleDeleteLine = async (line_id: never) => {
    if (typeof id === 'undefined') {
      dispatch(
        setPopAlert({
          alertType: 'error',
          message: 'El grupo de orden de trabajo es requerido',
          show: true,
        })
      );
      return;
    }
    dispatch(setLoading(true));
    try {
      await apiCall(
        'DELETE',
        `groups-ot/${id}/remove/${line_id}/`,
        null,
        'Línea eliminada exitosamente',
        'Error al eliminar la línea del grupo'
      );
      dispatch(setLoading(false));
      setCurrentGroupOT({
        ...currentGroupOT,
        lines: currentGroupOT.lines.filter((line: LineType) => line.id !== line_id),
      });
      setAlreadySelected(alreadySelected.filter((lineId: string) => lineId !== line_id));
    } catch (e) {
      dispatch(setLoading(false));
      dispatch(
        setPopAlert({
          alertType: 'error',
          message: 'Error al eliminar la línea del grupo',
          show: true,
        })
      );
    }
  };

  const handleAddToGroup = async (line_id: never) => {
    if (typeof id === 'undefined') {
      dispatch(
        setPopAlert({
          alertType: 'error',
          message: 'El grupo de orden de trabajo es requerido',
          show: true,
        })
      );
      return;
    }

    if (!alreadySelected?.includes(line_id as never)) {
      setAlreadySelected([...alreadySelected, line_id]);
      const line = lines.filter((line: LineType) => line.id === line_id)?.[0];
      if (isNaN(parseInt(id)) || isNaN(line.id)) {
        dispatch(
          setPopAlert({
            alertType: 'error',
            message: 'Error intentando enviar los datos necesarios para añadir la linea al grupo',
            show: true,
          })
        );
        return;
      }

      dispatch(setLoading(true));

      try {
        await apiCall(
          'POST',
          `groups-ot/${id}/add/${line_id}/`,
          null,
          'Línea agregada exitosamente',
          'Error al agregar la línea al grupo'
        );
        dispatch(setLoading(false));
        setCurrentGroupOT({ ...currentGroupOT, lines: [...currentGroupOT.lines, line] });
      } catch (e) {
        dispatch(setLoading(false));
        dispatch(
          setPopAlert({
            alertType: 'error',
            message: 'Error al agregar la línea al grupo',
            show: true,
          })
        );
        return;
      }
    }
  };

  return (
    <div>
      <h1>Configuraciones | Líneas de {currentGroupOT.group_name} </h1>

      <div className='container-wrap'>
        <Button onClick={() => navigate(-1)} style={{ width: 300, margin: 'auto' }}>
          Volver
        </Button>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} className='text-left'>
            <ViewLines hideActions={true} handleAddToGroup={handleAddToGroup} alreadySelected={alreadySelected} />
          </Grid>
          <Grid item xs={12} sm={6} className='text-right'>
            <h2>Lineas Agregadas</h2>
            <div className='table-responsive'>
              <TableContainer className='table-container'>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <b>NOMBRE</b>
                      </TableCell>
                      <TableCell width={100}>
                        <b>ACCIONES</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentGroupOT?.lines?.map((l: LineType) => (
                      <TableRow key={l.id}>
                        <TableCell>{l.line_name}</TableCell>
                        <TableCell>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Button onClick={() => handleDeleteLine(l.id as never)}>
                                <FontAwesomeIcon icon={faTrash} />
                              </Button>
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ViewGroupsOTLines;
