import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

const initialState = {
    groups : [],
    process_roles : [],
}

export const permissionsSlice = createSlice({
    name: 'permissions',
    initialState,
    reducers: {
        setGroups: (state, action: PayloadAction<any>) => {
            state.groups = action.payload;
        },
        setProcessRoles: (state, action: PayloadAction<any>) => {
            state.process_roles = action.payload;
        }
    },
})

export const { setGroups, setProcessRoles } = permissionsSlice.actions

export const selectGroups = (state: RootState) => state.permissions.groups
export const selectProcessRoles = (state: RootState) => state.permissions.process_roles

