import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { ConsecutiveType, OrderType } from './types';

import logo from './assets/images/logo-raptor.png';

const handleErrors = (response: Response) => {
  if (response.status === 401) {
    localStorage.clear();
    if (window.location.pathname !== '/login') window.location.href = '/login';
    return;
  }
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
};

const checkPermissions = (permission: string) => {
  const userPermissions = JSON.parse(localStorage.getItem('user') || '{}').permissions;
  if (!userPermissions) return false;
  return userPermissions.includes(permission);
};

const usePermissions = (permissions: string[]) => {
  const permissionsMap: { [key: string]: boolean } = {};
  permissions.forEach((permission) => {
    permissionsMap[`can${permission.charAt(0).toUpperCase()}${permission.slice(1)}`] = checkPermissions(permission);
  });
  return permissionsMap;
};

const checkProcessPermissions = (processPermission: string) => {
  const userProcessPermissions = JSON.parse(localStorage.getItem('user') || '{}').process_permissions;
  if (!userProcessPermissions) return false;
  return userProcessPermissions.includes(processPermission);
};

const useProcessPermissions = (processPermissions: string) => {
  return checkProcessPermissions(processPermissions);
};

const checkGroups = (group: string) => {
  const userGroups = JSON.parse(localStorage.getItem('user') || '{}').groups;
  if (!userGroups) return false;
  return userGroups.includes(group);
};

const useGroups = (groups: string[]) => {
  const groupsMap: { [key: string]: boolean } = {};
  groups.forEach((group) => {
    // remove spaces
    const groupNoSpaces = group.replace(/\s/g, '');
    groupsMap[`is${groupNoSpaces.charAt(0).toUpperCase()}${groupNoSpaces.slice(1)}`] = checkGroups(group);
  });

  return groupsMap;
};

type boceto = string | null;

const printOrderTable = async (order: OrderType, boceto?: boceto) => {
  const newWidth = 100;
  const newHeight = (await getDimensionsHeight(logo, newWidth, true)) as number;

  const bocetoWidth = 150;
  let bocetoHeight = 0;
  if (boceto) {
    bocetoHeight = (await getDimensionsHeight(boceto, bocetoWidth)) as number;
  }

  return new Promise((resolve, reject) => {
    const doc = new jsPDF();
    const {
      id,
      customer,
      line,
      created_by,
      order_date,
      production_date_end,
      production_date_start,
      delivery_date,
      replica,
      gift,
      warranty,
      clothing_quantity,
      clothing_neck,
      clothing_cut,
      socks_quantity,
      socks_color,
      quotation_number,
    } = order;
    const date = new Date().toLocaleDateString();
    const time = new Date().toLocaleTimeString();

    doc.addImage(logo, 10, 10, newWidth, newHeight);

    // calcular ancho de la hoja
    const pageWidth = doc.internal.pageSize.getWidth();

    doc.setFontSize(14);
    doc.setFont('helvetica', 'bold');
    // calcular ancho de texto
    const ordenNo: string = `Orden #${id}`;
    const ordenNoWidth = doc.getTextWidth(ordenNo);
    // alinear texto a la derecha
    doc.text(ordenNo, pageWidth - ordenNoWidth - 10, 25);

    const cotizacion: string = `Cotización ${quotation_number}`;
    const cotizacionWidth = doc.getTextWidth(cotizacion);
    // alinear texto a la derecha
    doc.text(cotizacion, pageWidth - cotizacionWidth - 10, 30);

    doc.setFontSize(9);
    doc.setFont('helvetica', 'italic');

    // calcular ancho de texto
    const fechaImpresion: string = `Fecha de Impresión: ${date} ${time}`;
    const fechaImpresionWidth = doc.getTextWidth(fechaImpresion);
    // alinear texto a la derecha
    doc.text(fechaImpresion, pageWidth - fechaImpresionWidth - 10, 20);

    doc.setFontSize(14);
    doc.setFont('helvetica', 'normal');

    const coorY = newHeight + 25;

    // table
    const table = [
      ['Cliente', { content: customer.customer_name, colSpan: 3 }],
      ['Linea', line.line_name, 'Creada por', created_by.name ? created_by.name : created_by.username],
      ['Fecha', order_date, 'Inicio de Producción', production_date_start ? production_date_start : '-'],
      [
        'Fin de Producción',
        production_date_end ? production_date_end : '-',
        'Fecha de Entrega',
        delivery_date ? delivery_date : '-',
      ],
      ['Replica', replica !== '' ? replica : '-', 'Obsequio', gift ? 'Sí' : 'No'],
      ['Garantía', warranty ? 'Sí' : 'No', 'Cantidad de Prendas', clothing_quantity ? clothing_quantity : '0'],
      ['Cuello', clothing_neck ? clothing_neck : 'N/A', 'Corte', clothing_cut ? clothing_cut : 'N/A'],
      [
        'Cantidad de Medias',
        socks_quantity ? socks_quantity : '0',
        'Color de Medias',
        socks_color ? socks_color : 'N/A',
      ],
    ];

    autoTable(doc, {
      head: undefined,
      body: table,
      startY: coorY + 5,
      alternateRowStyles: { fillColor: '#f3f3f3' },
      columnStyles: { 0: { fontStyle: 'bold' }, 2: { fontStyle: 'bold' } },
    });

    if (boceto) {
      autoTable(doc, {
        head: [['Boceto']],
        body: [[]],
        didDrawCell: (data) => {
          if (data.section === 'body' && data.row.index === 0 && data.column.index === 0) {
            doc.addImage(boceto, data.cell.x + 2, data.cell.y + 2, bocetoWidth, bocetoHeight);
          }
        },
      });
    }

    if (doc.save(`order_${order.id}.pdf`) === undefined) {
      reject();
    }
    resolve(true);
  });
};

const printConsecutiveTable = async (consecutive: any, boceto?: boceto) => {
  const newWidth = 100;
  const newHeight = (await getDimensionsHeight(logo, newWidth, true)) as number;

  const bocetoWidth = 150;
  let bocetoHeight = 0;
  if (boceto) {
    bocetoHeight = (await getDimensionsHeight(boceto, bocetoWidth)) as number;
  }

  return new Promise((resolve, reject) => {
    const doc = new jsPDF();
    const {
      order,
      consecutive_number,
      created_at,
      replica,
      gift,
      warranty,
      clothing_quantity,
      clothing_neck,
      clothing_cut,
      socks_quantity,
      socks_color,
      created_by,
      line,
    } = consecutive;

    const date = new Date().toLocaleDateString();
    const time = new Date().toLocaleTimeString();

    doc.addImage(logo, 10, 10, newWidth, newHeight);

    // calcular ancho de la hoja
    const pageWidth = doc.internal.pageSize.getWidth();

    doc.setFontSize(14);
    doc.setFont('helvetica', 'bold');
    // calcular ancho de texto
    const consecutivoNo: string = `Consecutivo #${consecutive_number} - Orden #${order.id}`;
    const consecutivoNoWidth = doc.getTextWidth(consecutivoNo);
    // alinear texto a la derecha
    doc.text(consecutivoNo, pageWidth - consecutivoNoWidth - 10, 25);

    const cotizacion: string = `Cotización ${order.quotation_number}`;
    const cotizacionWidth = doc.getTextWidth(cotizacion);
    // alinear texto a la derecha
    doc.text(cotizacion, pageWidth - cotizacionWidth - 10, 30);

    doc.setFontSize(9);
    doc.setFont('helvetica', 'italic');

    // calcular ancho de texto
    const fechaImpresion: string = `Fecha de Impresión: ${date} ${time}`;
    const fechaImpresionWidth = doc.getTextWidth(fechaImpresion);
    // alinear texto a la derecha
    doc.text(fechaImpresion, pageWidth - fechaImpresionWidth - 10, 20);

    doc.setFontSize(14);
    doc.setFont('helvetica', 'normal');

    const coorY = newHeight + 25;
    const fechaConsecutivo = new Date(created_at).toLocaleDateString();
    // tabla
    const table = [
      ['Cliente', { content: order.customer.customer_name, colSpan: 3 }],
      ['Linea', line.line_name, 'Creada por', created_by.name ? created_by.name : created_by.username],
      [
        'Fecha',
        fechaConsecutivo,
        'Inicio de Producción',
        order.production_date_start ? order.production_date_start : '-',
      ],
      [
        'Fin de Producción',
        order.production_date_end ? order.production_date_end : '-',
        'Fecha de Entrega',
        order.delivery_date ? order.delivery_date : '-',
      ],
      ['Replica', replica !== '' ? replica : '-', 'Obsequio', gift ? 'Sí' : 'No'],
      ['Garantía', warranty ? 'Sí' : 'No', 'Cantidad de Prendas', clothing_quantity ? clothing_quantity : '0'],
      ['Cuello', clothing_neck ? clothing_neck : 'N/A', 'Corte', clothing_cut ? clothing_cut : 'N/A'],
      [
        'Cantidad de Medias',
        socks_quantity ? socks_quantity : '0',
        'Color de Medias',
        socks_color ? socks_color : 'N/A',
      ],
    ];

    autoTable(doc, {
      head: undefined,
      body: table,
      startY: coorY + 5,
      alternateRowStyles: { fillColor: '#f3f3f3' },
      columnStyles: { 0: { fontStyle: 'bold' }, 2: { fontStyle: 'bold' } },
    });

    if (boceto) {
      autoTable(doc, {
        head: [['Boceto']],
        body: [[]],
        didDrawCell: (data) => {
          if (data.section === 'body' && data.row.index === 0 && data.column.index === 0) {
            doc.addImage(boceto, data.cell.x + 2, data.cell.y + 2, bocetoWidth, bocetoHeight);
          }
        },
      });
    }

    if (doc.save(`order_${order.id}_consecutive_${consecutive.id}.pdf`) === undefined) {
      reject();
    }
    resolve(true);
  });
};

const printConsecutiveTableBatch = async (consecutives: ConsecutiveType[]) => {
  const newWidth = 100;
  const newHeight = (await getDimensionsHeight(logo, newWidth, true)) as number;

  const bocetoWidth = 150;
  const consecutivesHeights = (await getDimensionsHeightBatch(
    consecutives.map((consecutive) => (consecutive.image_cache ? consecutive.image_cache : '')),
    bocetoWidth
  )) as number[];

  return new Promise((resolve, reject) => {
    const doc = new jsPDF();
    const date = new Date().toLocaleDateString();
    const time = new Date().toLocaleTimeString();

    consecutives.forEach((consecutive, index) => {
      if (index > 0) doc.addPage();

      doc.addImage(logo, 10, 10, newWidth, newHeight);

      // calcular ancho de la hoja
      const pageWidth = doc.internal.pageSize.getWidth();

      doc.setFontSize(14);
      doc.setFont('helvetica', 'bold');
      // calcular ancho de texto
      const consecutivoNo: string = `Consecutivo #${consecutive.consecutive_number} - Orden #${consecutive.order.id}`;
      const consecutivoNoWidth = doc.getTextWidth(consecutivoNo);
      // alinear texto a la derecha
      doc.text(consecutivoNo, pageWidth - consecutivoNoWidth - 10, 25);

      const cotizacion: string = `Cotización ${consecutive.order.quotation_number}`;
      const cotizacionWidth = doc.getTextWidth(cotizacion);
      // alinear texto a la derecha
      doc.text(cotizacion, pageWidth - cotizacionWidth - 10, 30);

      doc.setFontSize(9);
      doc.setFont('helvetica', 'italic');

      // calcular ancho de texto
      const fechaImpresion: string = `Fecha de Impresión: ${date} ${time}`;
      const fechaImpresionWidth = doc.getTextWidth(fechaImpresion);
      // alinear texto a la derecha
      doc.text(fechaImpresion, pageWidth - fechaImpresionWidth - 10, 20);

      doc.setFontSize(14);
      doc.setFont('helvetica', 'normal');

      const coorY = newHeight + 25;
      const fechaConsecutivo = new Date(consecutive.created_at).toLocaleDateString();
      // tabla
      const table = [
        ['Cliente', { content: consecutive.order.customer.customer_name, colSpan: 3 }],
        [
          'Linea',
          consecutive.order.line.line_name,
          'Creada por',
          consecutive.order.created_by.name ? consecutive.order.created_by.name : consecutive.order.created_by.username,
        ],
        [
          'Fecha',
          fechaConsecutivo,
          'Inicio de Producción',
          consecutive.order.production_date_start ? consecutive.order.production_date_start : '-',
        ],
        [
          'Fin de Producción',
          consecutive.order.production_date_end ? consecutive.order.production_date_end : '-',
          'Fecha de Entrega',
          consecutive.order.delivery_date ? consecutive.order.delivery_date : '-',
        ],
        ['Replica', consecutive.replica !== '' ? consecutive.replica : '-', 'Obsequio', consecutive.gift ? 'Sí' : 'No'],
        [
          'Garantía',
          consecutive.warranty ? 'Sí' : 'No',
          'Cantidad de Prendas',
          consecutive.clothing_quantity ? consecutive.clothing_quantity : '0',
        ],
        [
          'Cuello',
          consecutive.clothing_neck ? consecutive.clothing_neck : 'N/A',
          'Corte',
          consecutive.clothing_cut ? consecutive.clothing_cut : 'N/A',
        ],
        [
          'Cantidad de Medias',
          consecutive.socks_quantity ? consecutive.socks_quantity : '0',
          'Color de Medias',
          consecutive.socks_color ? consecutive.socks_color : 'N/A',
        ],
      ];

      autoTable(doc, {
        head: undefined,
        body: table,
        startY: coorY + 5,
        alternateRowStyles: { fillColor: '#f3f3f3' },
        columnStyles: { 0: { fontStyle: 'bold' }, 2: { fontStyle: 'bold' } },
      });

      if (consecutive.image_cache) {
        autoTable(doc, {
          head: [['Boceto']],
          body: [[]],
          didDrawCell: (data) => {
            if (data.section === 'body' && data.row.index === 0 && data.column.index === 0) {
              if (consecutive.image_cache) {
                doc.addImage(
                  consecutive.image_cache,
                  data.cell.x + 2,
                  data.cell.y + 2,
                  bocetoWidth,
                  consecutivesHeights[index]
                );
              } else {
                doc.text('No hay boceto', data.cell.x + 2, data.cell.y + 2);
              }
            }
          },
        });
      }
    });

    if (doc.save(`consecutives.pdf`) === undefined) {
      reject();
    }

    resolve(true);
  });
};

const getDimensionsHeight = async (imageSrc: string, widthSize: number, isBase64?: boolean) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    if (isBase64) img.crossOrigin = 'Anonymous';
    img.src = imageSrc;

    try {
      img.onload = () => {
        const imgWidth = img.width;
        const imgHeight = img.height;
        if (isNaN(imgWidth) || isNaN(imgHeight)) {
          console.error('Error getting image dimensions.');
          reject();
        }
        const newHeight = (imgHeight * widthSize) / imgWidth;
        resolve(newHeight);
      };
      img.onerror = (e) => {
        console.error(e);
        console.error('Error loading image.');
        reject();
      };
    } catch (e) {
      console.error(e);
      reject();
    }
  });
};

const getDimensionsHeightBatch = async (imageSrcs: string[], widthSize: number, isBase64?: boolean) => {
  const heights: number[] = [];
  return new Promise((resolve, reject) => {
    imageSrcs.forEach((imageSrc, index) => {
      if (!imageSrc) {
        heights.push(0);
        if (index === imageSrcs.length - 1) resolve(heights);
        return;
      }
      const img = new Image();
      if (isBase64) img.crossOrigin = 'Anonymous';
      img.src = imageSrc;

      try {
        img.onload = () => {
          const imgWidth = img.width;
          const imgHeight = img.height;
          if (isNaN(imgWidth) || isNaN(imgHeight)) {
            console.error('Error getting image dimensions.');
            reject();
          }
          const newHeight = (imgHeight * widthSize) / imgWidth;
          heights.push(newHeight);
          if (index === imageSrcs.length - 1) resolve(heights);
        };
        img.onerror = () => {
          console.error('Error loading image.');
          reject();
        };
      } catch (e) {
        console.error(e);
        reject();
      }
    });
  });
};

export {
  handleErrors,
  usePermissions,
  useGroups,
  useProcessPermissions,
  getDimensionsHeight,
  printOrderTable,
  printConsecutiveTable,
  printConsecutiveTableBatch,
};
