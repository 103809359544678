import { Grid } from "@mui/material"
import { Button } from "../../components/shared/FormElements";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { useAppDispatch } from '../../hooks';
import { useAppContext } from '../../Context';

import { setPopAlert } from "../../slices/miscSlice";
import { setLoading } from "../../slices/miscSlice";
const ViewCustomers: React.FC = () => {
    const dispatch = useAppDispatch();
    const { apiCall } = useAppContext();

    const handleRefreshClients = async () => {
        dispatch(setLoading(true));
        const response = await apiCall('GET', 'customers/refresh/', null, '', 'Error al obtener los clientes');
        dispatch(setLoading(false));
        if (response) {
            dispatch(setPopAlert({
                alertType: 'success',
                message: 'Clientes actualizados correctamente',
                show: true
            }));
        }else{
            dispatch(setPopAlert({
                alertType: 'error',
                message: 'Error al actualizar los clientes',
                show: true
            }));
        }
    }

    return <>
         <h2>Lista de Clientes</h2>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={3} className="text-right">

                            </Grid>
                            <Grid item xs={12} sm={6} className="text-center">

                            <Button onClick={handleRefreshClients}><FontAwesomeIcon icon={faRefresh} spin /> Actualizar Clientes desde Siigo</Button>
                            </Grid>
                            <Grid item xs={12} sm={3} className="text-right"></Grid>
                        </Grid>
    </>
}

export default ViewCustomers