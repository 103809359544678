import { useState } from "react";
import { Grid, TableContainer, TableCell, TableRow, TableBody, TableHead, Table } from "@mui/material";
import { Button } from "../../components/shared/FormElements";
import { GroupOTType } from "../../types"; // Asegúrate de tener el tipo adecuado para GroupOT
import { useAppSelector, useAppDispatch } from "../../hooks";

import { setLoading } from "../../slices/miscSlice";
import { setGroupsOT } from "../../slices/groupsOTSlice"; // Slice para manejar el estado de GroupOT
import { setPopAlert } from "../../slices/miscSlice";
import { useAppContext } from "../../Context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListAlt, faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import '../../styles/DataTable.css';
import { useNavigate } from "react-router-dom";

const ViewGroupsOT: React.FC = () => {
    const [addNewForm, setAddNewForm] = useState(false);
    const [editForm, setEditForm] = useState(false);
    const [newGroupOT, setNewGroupOT] = useState('' as string);
    const [editGroupOT, setEditGroupOT] = useState({} as GroupOTType);
    const { groupsOT } = useAppSelector(state => state.groupsOT); // Estado de GroupOT
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { apiCall } = useAppContext();

    const handleSaveGroupOT = async () => {
        if (newGroupOT === '') {
            dispatch(setPopAlert({
                alertType: 'error',
                message: 'El nombre del grupo OT es requerido',
                show: true
            }));
            return;
        }
        dispatch(setLoading(true));
        const data = {
            group_name: newGroupOT
        }
        const response = await apiCall('POST', 'groups-ot/', data, 'Grupo OT creado exitosamente', 'Error al crear el grupo OT');
        dispatch(setLoading(false));
        if (response.id) {
            dispatch(setGroupsOT([...groupsOT, response]));
            setAddNewForm(false);
            setNewGroupOT('');
        } else {
            dispatch(setPopAlert({
                alertType: 'error',
                message: 'Error al crear el grupo OT',
                show: true
            }));
        }
    }

    const handleEditGroupOT = (groupOT: GroupOTType) => {
        setEditForm(true);
        setEditGroupOT(groupOT);
    }

    const handleUpdateGroupOT = async () => {
        if (editGroupOT.group_name === '') {
            dispatch(setPopAlert({
                alertType: 'error',
                message: 'El nombre del grupo OT es requerido',
                show: true
            }));
            return;
        }
        dispatch(setLoading(true));
        const data = {
            group_name: editGroupOT.group_name
        }
        const response = await apiCall('PUT', `groups-ot/${editGroupOT.id}/`, data, 'Grupo OT actualizado exitosamente', 'Error al actualizar el grupo OT');
        dispatch(setLoading(false));
        if (response?.id) {
            const updatedGroupOTs = groupsOT.map((groupOT: GroupOTType) => {
                if (groupOT.id === response.id) {
                    return response;
                }
                return groupOT;
            });
            dispatch(setGroupsOT(updatedGroupOTs));
            setEditForm(false);
            setEditGroupOT({} as GroupOTType);
        } else {
            dispatch(setPopAlert({
                alertType: 'error',
                message: 'Error al actualizar el grupo OT',
                show: true
            }));
        }
    }

    const handleDeleteGroupOT = async (id: number) => {
        dispatch(setLoading(true));
        const response = await apiCall('DELETE', `groups-ot/${id}/`, null, 'Grupo OT eliminado exitosamente', 'Error al eliminar el grupo OT');
        dispatch(setLoading(false));
        if (response) {
            const updatedGroupOTs = groupsOT.filter((groupOT: GroupOTType) => groupOT.id !== id);
            dispatch(setGroupsOT(updatedGroupOTs));
        }
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={10} className="text-right">
                    <h2>Grupos OT</h2>
                </Grid>
                <Grid item xs={12} sm={2} className="text-right">
                    <Button onClick={() => setAddNewForm(true)}>Agregar</Button>
                </Grid>
            </Grid>
            {addNewForm && (
                <div>
                    <h3>Agregar Nuevo Grupo OT</h3>
                    <form>
                        <input type="text" placeholder="Nombre del grupo OT" value={newGroupOT} onChange={(e) => setNewGroupOT(e.target.value)} />
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Button onClick={() => setAddNewForm(false)}>Cancelar</Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button onClick={handleSaveGroupOT}>Guardar</Button>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            )}
            {editForm && (
                <div>
                    <h3>Editar Grupo OT</h3>
                    <form>
                        <input type="text" placeholder="Nombre del grupo OT" value={editGroupOT.group_name} onChange={(e) => setEditGroupOT({ ...editGroupOT, group_name: e.target.value })} />
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Button onClick={() => setEditForm(false)}>Cancelar</Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button onClick={handleUpdateGroupOT}>Guardar Cambios</Button>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            )}
            {groupsOT?.length === 0 && <p>No hay grupos OT registrados</p>}
            <Grid container spacing={2}>
                <div className="table-responsive">
                    <TableContainer className="table-container">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell><b>NOMBRE</b></TableCell>
                                    <TableCell width={150}><b>ACCIONES</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {groupsOT?.map((groupOT: GroupOTType) => (
                                    <TableRow key={groupOT.id}>
                                        <TableCell>{groupOT.group_name}</TableCell>
                                        <TableCell>
                                            <Grid container spacing={2}>
                                                <Grid item xs={4}>  
                                                    <Button onClick={() => navigate(`/grupo-ot/${groupOT.id}/lineas`)}><FontAwesomeIcon icon={faListAlt} /></Button>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Button onClick={() => handleEditGroupOT(groupOT)}><FontAwesomeIcon icon={faPencil} /></Button>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Button onClick={() => handleDeleteGroupOT(groupOT.id)}><FontAwesomeIcon icon={faTrash} /></Button>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Grid>
        </>
    );
}

export default ViewGroupsOT;
