import { Autocomplete, Grid, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Label } from '../../../components/shared/FormElements';
import { AssignmentType, ColumnsType, NullableOrderType, OrderType, TransactionType } from '../../../types';
import { useAppSelector } from '../../../hooks';
import { usePermissions } from '../../../services';
import { useAppContext } from '../../../Context';
import { formatNumber, parseNumber } from '../../../Utils';
import { useDispatch } from 'react-redux';
import { setTransaction } from '../../../slices/transactionsSlice';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataTable from '../../../components/shared/DataTable';

const Assignments: React.FC<{ assignments: AssignmentType[]; getAssignments: Function }> = ({
  assignments,
  getAssignments,
}) => {
  const { id, code } = useParams<{ id: string; code: string }>();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [currentTransaction, setCurrentTransaction] = useState<TransactionType | null>();
  const [currentAssignment, setCurrentAssignment] = useState<AssignmentType | null>();
  const [orders, setOrders] = useState<OrderType[]>([]);
  const assignment: AssignmentType = useAppSelector((state) => state.transactions.assignment);
  const { apiCall } = useAppContext();
  const dispatch = useDispatch();

  const { canView_assignments, canAdd_assignments, canChange_assignments, canDelete_assignments } = usePermissions([
    'view_assignments',
    'add_assignments',
    'change_assignments',
    'delete_assignments',
  ]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!canView_assignments) {
      navigate('/movimientos');
    }

    if (!code) {
      return;
    }
    getCurrentTransaction();

    // eslint-disable-next-line
  }, [id]);

  const getCurrentTransaction = async () => {
    const response = await apiCall(
      'GET',
      `transaction/${code}/`,
      null,
      '',
      'Los datos de la transacción no se pudieron cargar.'
    );
    if (response) {
      setCurrentTransaction(response);
      dispatch(setTransaction(response));
      if (response.customer) {
        getOrders(response.customer.id);
      }

      getAssignments();
    }
  };

  const getOrders = async (customerId: number) => {
    const response: OrderType[] = await apiCall(
      'GET',
      `orders/all/customer/${customerId}/`,
      null,
      '',
      'Los datos de las órdenes no se pudieron cargar.'
    );
    if (response) {
      setOrders(response);
    }
  };

  const handleSave = async () => {
    if (currentAssignment?.id) {
      const response = await apiCall(
        'PUT',
        `transaction/${code}/assignments/${currentAssignment?.id}/`,
        currentAssignment,
        '',
        'Los datos de la asignación no se pudieron guardar.'
      );
      if (response) {
        getCurrentTransaction();
        setCurrentAssignment(null);
        setEditMode(false);
      }
    } else {
      const assignedValues = assignments.reduce(
        (sum, assignment) => sum + (parseFloat(assignment.assigned_value.toString()) || 0), // Asegúrate de obtener números
        0
      );
      const master_value = currentTransaction?.master_value || 0;
      if (assignedValues + (currentAssignment?.assigned_value || 0) > master_value) {
        alert('El valor total de las asignaciones no puede superar el valor total de la transacción');
        return;
      }
      const response = await apiCall(
        'POST',
        `transactions/${code}/assignments/`,
        currentAssignment,
        '',
        'Los datos de la asignación no se pudieron guardar.'
      );
      if (response) {
        getCurrentTransaction();
        setCurrentAssignment(null);
        setEditMode(false);
      }
    }
  };

  const handleEditar = (assignmentObj: AssignmentType) => {
    setEditMode(true);
    const assignment = assignments.find((assignment) => assignment.id === assignmentObj.id);
    if (assignment) {
      setCurrentAssignment(assignment);
      // scroll to  #editAssignment
      const element = document.getElementById('editAssignment');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      alert('No se encontró la asignación');
    }
  };

  const handleDelete = async (assignmentObj: AssignmentType) => {
    const response = await apiCall(
      'DELETE',
      `transaction/${code}/assignments/${assignmentObj.id}/`,
      null,
      '',
      'La asignación no se pudo eliminar.'
    );
    if (response) {
      getCurrentTransaction();
    }
  };

  const columns: ColumnsType[] = [
    {
      title: 'Orden',
      field: 'order.id',
      cell_class: 'text-center',
    },
    {
      title: 'Concepto',
      field: 'concept',
      cell_class: 'text-center',
    },
    {
      title: 'Valor Asignado',
      field: 'assigned_value',
      type: 'currencyformat',
      cell_class: 'text-center',
    },
    {
      title: 'Fecha de Asignación',
      field: 'created_at',
      type: 'datetime',
      cell_class: 'text-center',
    },
    {
      title: 'Asignado Por',
      field: 'created_by.username',
      cell_class: 'text-center',
    },
  ];

  const actions: Array<any> = [
    canChange_assignments
      ? {
          icon: <FontAwesomeIcon icon={faPen} />,
          tooltip: 'Editar',
          onClick: (rowData: any) => handleEditar(rowData),
        }
      : null,
    canDelete_assignments
      ? {
          icon: <FontAwesomeIcon icon={faTrash} />,
          tooltip: 'Eliminar',
          onClick: (rowData: any) => handleDelete(rowData),
        }
      : null,
  ];

  const currentAssignedValues = assignments?.reduce(
    (sum, assignment) => sum + (parseFloat(assignment.assigned_value.toString()) || 0), // Asegúrate de obtener números
    0
  );

  const currentMaster = currentTransaction?.master_value ? parseFloat(currentTransaction?.master_value.toString()) : 0;
  const fullyAssigned = currentMaster === currentAssignedValues;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} className='text-left'>
          <h3 className='text-left'>Asignaciones</h3>
        </Grid>
        <Grid item xs={12} sm={6} className='text-right'>
          {canAdd_assignments && !editMode && (
            <Button
              className='btn btn-primary'
              style={{ width: 300 }}
              onClick={() => setEditMode(true)}
              disabled={fullyAssigned}
              title={fullyAssigned ? 'No se pueden agregar más asignaciones' : ''}
            >
              Agregar Asignación
            </Button>
          )}
        </Grid>
      </Grid>
      {editMode ? (
        <>
          <div id='editAssignment' className='form-container'>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={4} className='text-left'>
                <div className='form-group'>
                  <Label>Orden</Label>
                  <Autocomplete
                    options={[{ id: null, customer: { customer_name: 'Ninguna Orden Asociada' } }, ...orders]} // Agrega la opción nula al inicio
                    getOptionLabel={(option) =>
                      option.id === null || option.id === 0
                        ? 'Ninguna Orden Asociada'
                        : `${option.id} - ${option.customer.customer_name}`
                    }
                    value={
                      currentAssignment?.order || { id: null, customer: { customer_name: 'Ninguna Orden Asociada' } }
                    } // Usa el valor por defecto si es nulo
                    onChange={(e, value) => {
                      if (!value || typeof value === 'undefined') return;
                      const orderNullable: NullableOrderType = value;
                      setCurrentAssignment({ ...currentAssignment, order: orderNullable } as AssignmentType);
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value.id} // Compara solo el id
                    renderInput={(params) => <TextField {...params} />}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} className='text-left'>
                <div className='form-group'>
                  <Label>Concepto</Label>
                  <TextField
                    type='text'
                    style={{ width: '100%' }}
                    value={currentAssignment?.concept}
                    onChange={(e) =>
                      setCurrentAssignment({ ...currentAssignment, concept: e.target.value } as AssignmentType)
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} className='text-left'>
                <div className='form-group'>
                  <Label>Valor Asignado</Label>
                  <TextField
                    className='form-control'
                    type='text' // Cambia el tipo a 'text' para poder controlar el formato
                    value={formatNumber(currentAssignment?.assigned_value ?? 0)}
                    onChange={(event: { target: { value: any } }) => {
                      const rawValue = event.target.value;
                      const parsedValue: number = parseNumber(rawValue) ?? 0;

                      // Si está dentro del límite, actualiza el estado
                      setCurrentAssignment({
                        ...currentAssignment,
                        assigned_value: parsedValue,
                      } as AssignmentType);
                    }}
                    onFocus={(event: { target: { select: () => any } }) => event.target.select()}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
          {editMode && currentAssignment?.id ? (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} className='text-right'>
                <span className='text-log'>
                  <b>Última Modificación:</b> {new Date(assignment?.updated_at).toLocaleString('es-CO')} -{' '}
                  <b>Fecha de Creación:</b> {new Date(assignment?.created_at).toLocaleString('es-CO')}
                </span>
              </Grid>
            </Grid>
          ) : null}
          <Grid container spacing={4}>
            <Grid item xs={12} sm={2} className='text-right'>
              <Button className='btn btn-primary' onClick={handleSave}>
                Guardar
              </Button>
            </Grid>
          </Grid>
        </>
      ) : null}
      <Grid container spacing={2} marginTop={3}>
        {assignments.length > 0 ? (
          <Grid item xs={12} sm={12} className='text-left'>
            <DataTable
              columns={columns}
              data={assignments}
              actions={canChange_assignments || canDelete_assignments ? actions : null}
            />
          </Grid>
        ) : (
          <Grid item xs={12} sm={12} className='text-left'>
            <p>No hay asignaciones para este movimiento</p>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default Assignments;
