import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { useAppContext} from '../../Context';
import { useAppDispatch, useAppSelector } from '../../hooks';

import { setConsecutive } from '../../slices/ordersSlice';
import { setLines } from '../../slices/linesSlice';
import { ConsecutiveType, LineType } from '../../types';

import { usePermissions, printConsecutiveTable } from '../../services';
import { setLoading } from '../../slices/miscSlice';
import { LoaderTemporal } from '../../components/shared/Loader';
import Grid from '@mui/material/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { Button, Label } from '../../components/shared/FormElements';



const LoadImage : React.FC<{order_id: number, consecutive_id: number}> = ({order_id, consecutive_id}) => {
    const {apiCall} = useAppContext();
    const [blob, setBlob] = useState<string>("Cargando...");
    const dispatch = useAppDispatch();
    const consecutive : ConsecutiveType = useAppSelector((state: any) => state.orders.consecutive);

    useEffect(() => {
        if (!order_id) return ;
        const getImage = async () => {
            const response = await apiCall('GET', `order/${order_id}/consecutive/${consecutive_id}/boceto/`, null, '', 'Error al obtener la imagen');
            if (response) {
                if (response.image) {
                    setBlob(response.image);
                    dispatch(setConsecutive({
                        ...consecutive,
                        image_cache: response.image
                    }));
                }else{
                    setBlob("No se ha agregado un boceto");
                }
            }
        }
        if (!consecutive.image_cache) getImage();
        else setBlob(consecutive.image_cache);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return blob !== "No se ha agregado un boceto" && blob !== "Cargando..." ? <> <img src={blob} alt="boceto" className='width-90' /></>: <>{blob}</>;
}


const ViewConsecutiveDetail : React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {apiCall} = useAppContext();
    const { id, consecutive_id } = useParams();

    const [viewMode, setViewMode] = useState<boolean>(false);
    
    const { canView_process } = usePermissions(['view_process']); 
    const consecutive : ConsecutiveType = useAppSelector((state: any) => state.orders.consecutive);
    const lineas : Array<LineType> = useAppSelector((state: any) => state.lines.lines);

    useEffect(() => {

        if (lineas?.length === 0) {
            const getLines = async () => {
                dispatch(setLoading(true));
                const response = await apiCall('GET', 'lines/', null, '', 'Error al obtener las líneas');
                dispatch(setLoading(false));
                dispatch(setLines(response));
            }
            getLines();
        }

        if (id && consecutive_id && !canView_process ) {
            navigate('/')
            return;
        }

        if (!id) {
            return;
        }

        setViewMode(true);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const getBoceto = async () => {
        const resp = await apiCall('GET', `order/${id}/consecutive/${consecutive_id}/boceto/`, null, '', 'Error al obtener el boceto');
        if (resp?.image){
            return resp.image;
        }
        return null;
    }

    const handlePrintConsecutive = async () => {
        let boceto = null
        if (consecutive.image_cache){
            boceto = consecutive.image_cache;
        }else{
            boceto = await getBoceto();
        }
        dispatch(setLoading(true));
        await printConsecutiveTable(consecutive, boceto);
        dispatch(setLoading(false));
    }

    if (consecutive_id && consecutive?.id === 0 ) return <LoaderTemporal />

    const { order, 
        consecutive_number, 
        created_by, 
        replica, 
        gift, 
        warranty, 
        created_at, 
        updated_at, 
        clothing_quantity,  
        line,
        clothing_cut,
        clothing_neck,
        socks_quantity,
        socks_color
    } = consecutive ? consecutive : { 
        order: null, 
        consecutive_number: null, 
        created_by: null, 
        replica: null, 
        gift: null, 
        warranty: null, 
        created_at: null, 
        updated_at: null, 
        clothing_quantity: 0,
        line: null,
        clothing_cut: null,
        clothing_neck: null,
        socks_quantity: 0,
        socks_color: null
     };

    const handleGoBack = () => {
        navigate(-1);
    }

    return <div className="container-wrap">
    <Grid container spacing={2}>
        <Grid item xs={12} sm={9} className="text-left">
            <h2 className="text-left">{ `Órden No. ${ id} - Consecutivo No. ${consecutive_number}` }</h2>
        </Grid> 
        <Grid item xs={12} sm={1} className="d-flex-center icon-btn">
            { viewMode ? <FontAwesomeIcon icon={faPrint} size="2x" className="text-primary" onClick={handlePrintConsecutive} /> : null}
        </Grid>
        <Grid item xs={12} sm={2} className="text-right">
            {canView_process && <Button className="btn btn-primary" onClick={handleGoBack}>Regresar a consecutivos</Button>}
        </Grid>
    </Grid> 
    <div className="form-container">
        <Grid container spacing={4}>
            <Grid item xs={12} sm={6} className="text-left">
                    <div className='form-group'>
                        <Label>Fecha de Órden</Label>
                        <div style={{display:"block"}} >{order?.order_date}</div>
                    </div>
                    <div className='form-group'>
                        <Label>Fecha de Consecutivo</Label>
                        <div style={{display:"block"}} >{created_at? new Date(created_at).toLocaleDateString() : "No registra"}</div>
                    </div>
                    <div className='form-group'>
                <Label>Cliente</Label>
                    
                        <> {order?.customer.customer_name} </>
                        
                </div>
                 <div className='form-group'>
                <Label>Línea</Label>
                        <> {line?.line_name} </>
                </div>
                <div className='form-group'>
                <Label>Corte de Prenda</Label>
                    <> {clothing_cut ? clothing_cut : "N/A"} </>
                </div>
                <div className='form-group'>
                <Label>Cuello de Prenda</Label>
                    <> {clothing_neck ? clothing_neck : "N/A"} </>
                </div>
                <div className='form-group'>
                <Label>Cantidad de Medias</Label>
                    <> {socks_quantity ? socks_quantity : 0} </>
                </div>
                <div className='form-group'>
                <Label>Color de Medias</Label>
                    <> {socks_color ? socks_color : "N/A"} </>
                       
                </div>
            </Grid>
            <Grid item xs={12} sm={6} className="text-left">
                <div className='form-group'>
                    <Label>Cantidad de prendas</Label>
                    <div style={{display:"block"}} >{clothing_quantity}</div> 
                </div>
                <div className='form-group'>
                <Label>¿Réplica?</Label>
                    <> {replica ? replica : "N/A"} </>
                </div>
                <div className='form-group'>
                <Label>¿Es obsequio o garantía?</Label>
                        <> {gift ? "Es un obsequio" : (warranty ? "Es una garantía" :"N/A" ) } </>
                </div>
                <div className='form-group'>
                    <Label>Boceto</Label>
                    { order?.id ? 
                        <LoadImage order_id={order?.id} consecutive_id={consecutive.id}/> 
                        : "No se ha agregado un boceto"
                    }              
                   
                </div>
            </Grid>
        </Grid> 
    </div>
    { viewMode? <Grid container spacing={2}>
        <Grid item xs={12} sm={12} className="text-right">
           <span className="text-log"><b>Última Modificación:</b> {updated_at? new Date(updated_at).toLocaleString('es-CO') : null} - <b>Creado por:</b> {created_by?.name ? created_by.name : created_by?.username } </span>
        </Grid>
    </Grid>:null }
    <br/>
</div>
}

export default ViewConsecutiveDetail;