import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { AssignmentType, TransactionType } from '../types';
import { initialStateOrders } from './ordersSlice';

// Define a type for the slice state
interface TransactionsState {
  transactions: Array<TransactionType>;
  transaction: TransactionType;
  assigments: Array<AssignmentType>;
  assigment: AssignmentType;
}

// Define the initial state using that type
const initialState: TransactionsState = {
  transactions: [],
  transaction: {
    code: 0,
    assigned_value: 0,
    master_value: 0,
    master_bank_code: '',
    customer: null,
    income_date: '',
    account: '',
    reported_by: null,
    status: '',
    observation: '',
    created_at: '',
    updated_at: '',
    status_updated_at: '',
    status_updated_by: null,
  },
  assigments: [],
  assigment: {
    id: 0,
    order: initialStateOrders.order,
    transaction: {
      code: 0,
      assigned_value: 0,
      master_value: 0,
      master_bank_code: '',
      customer: null,
      income_date: '',
      account: '',
      reported_by: null,
      status: '',
      observation: '',
      created_at: '',
      updated_at: '',
      status_updated_at: '',
      status_updated_by: null,
    },
    assigned_value: 0,
    concept: '',
    created_at: '',
    updated_at: '',
    created_by: null,
  },
};

export const transactionsSlice = createSlice({
  name: 'transactions',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setTransactions: (state, action: PayloadAction<Array<TransactionType>>) => {
      state.transactions = action.payload;
    },
    setTransaction: (state, action: PayloadAction<TransactionType>) => {
      state.transaction = action.payload;
    },
  },
});

export const { setTransactions, setTransaction } = transactionsSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectTransactions = (state: RootState) => state.transactions.transactions;
export const selectTransaction = (state: RootState) => state.transactions.transaction;
export const initialStateTransaction = initialState;
export default transactionsSlice.reducer;
