import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { usePermissions } from '../../services';

import { Chip, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Switch, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Button } from '../../components/shared/FormElements';

import { useAppContext } from '../../Context';

// react-redux hooks
import { useAppSelector, useAppDispatch } from '../../hooks';
import { initialStateUsers, setUser, setUsers } from '../../slices/usersSlice';

import { GroupType, ProcessRoleType, UserType } from '../../types';
import { setLoading, setPopAlert } from '../../slices/miscSlice';
import { setGroups, setProcessRoles } from '../../slices/permissionsSlice';
import { LoaderTemporal } from '../../components/shared/Loader';


const CreateEditUser : React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const users : Array<UserType> = useAppSelector((state) => state.users.users);
    const user : UserType = useAppSelector((state) => state.users.user );

    const groups : Array<GroupType> = useAppSelector((state) => state.permissions.groups);

    const process_roles : Array<ProcessRoleType> = useAppSelector((state) => state.permissions.process_roles);

    const { apiCall } = useAppContext();

    const { canView_user, canChange_user } = usePermissions(['view_user', 'change_user']);

    const [viewMode, setViewMode] = useState(false);
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        if (!canView_user ) {
            navigate('/usuarios');
            return;
        }

        if (groups.length === 0 ) getGroups();
        if (process_roles.length === 0 ) getProcessRoles();

        if (!id) {
            dispatch(setUser(initialStateUsers.user));
           return;
        }

        setViewMode(true);
        if(!user || user.id !== parseInt(id)){
            const current_user = users.find((user) => user.id === parseInt(id));
            if (current_user) {
                dispatch(setUser(current_user));
            }else{
                getUser();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getUser = async () => {
        dispatch(setLoading(true));
        const response = await apiCall('GET', `user/${id}/`, null, '', 'Error al obtener el usuario');
        if (response) {
            dispatch(setUser(response));
        }
        dispatch(setLoading(false));
    }

    const getGroups = async () => {
        dispatch(setLoading(true));
        const response = await apiCall('GET', 'groups/', null, '', 'Error al obtener los roles');
        dispatch(setLoading(false));
        if (response) {
            dispatch(setGroups(response));
        }
    }

    const getProcessRoles = async () => {
        dispatch(setLoading(true));
        const response = await apiCall('GET', 'process_roles/', null, '', 'Error al obtener los roles de procesos');
        dispatch(setLoading(false));
        if (response) {
            dispatch(setProcessRoles(response));
        }
    }

    const handleSave = async () => {
        dispatch(setPopAlert({show: false, message: '', alertType: 'info'}));
        if (!user.first_name || user.first_name === '') {
            dispatch(setPopAlert({show: true, message: 'El nombre es obligatorio', alertType: 'danger'}));
            return;
        }

        if (!user.last_name || user.last_name === '') {
            dispatch(setPopAlert({show: true, message: 'El apellido es obligatorio', alertType: 'danger'}));
            return;
        }

        if (!user.username || user.username === '') {
            dispatch(setPopAlert({show: true, message: 'El nombre de usuario es obligatorio', alertType: 'danger'}));
            return;
        }

        if (!user.email || user.email === '') {
            dispatch(setPopAlert({show: true, message: 'El email es obligatorio', alertType: 'danger'}));
            return;
        }

        if ((!user.password || user.password === '') && !editMode) {
            dispatch(setPopAlert({show: true, message: 'La contraseña es obligatoria', alertType: 'danger'}));
            return;
        }

        dispatch(setLoading(true));

        let response;
        if (editMode){
            response = await apiCall('PUT', `user/${user.id}/`, user, 'user', 'Error al guardar el usuario');
        }else{
            response = await apiCall('POST', 'user/', user, 'user', 'Error al guardar el usuario');
        }
        dispatch(setLoading(false));
        if (response) {
            const responseusers = await apiCall('GET', 'users/', null, '', 'Error al obtener los usuarios');
            if (responseusers) {
                dispatch(setUsers(responseusers));
                navigate('/usuarios');
            }
            setViewMode(true);
            setEditMode(false);
        }
    }

    const handleEditar = () => {
        setViewMode(false);
        setEditMode(true);
    }

    const handleChangeGroups = (e:any) => {
        if (e.target.checked){
            const selectedGroup = groups.find((group:GroupType) => group.name === e.target.name);
            const userGroups = user?.groups || [];
            if (selectedGroup && !userGroups.includes(selectedGroup)) {
                dispatch(setUser({...user, groups: [...userGroups, selectedGroup]})); 
            }
        }else{
            if ( typeof(user?.groups) !== "undefined") {
                if (user.groups.length > 0) dispatch(setUser({...user, groups: user?.groups?.filter((group:GroupType) => group.name !== e.target.name)})); 
            }
        }
    }
    
    const handleChangeProcessesRoles = (e:any) => {
        if (e.target.checked){
            const selectedProcessRole = process_roles.find((process_role:ProcessRoleType) => process_role.name === e.target.name);
            const userProcessRoles = user?.process_roles || [];
            if (selectedProcessRole && !userProcessRoles.includes(selectedProcessRole)) {
                dispatch(setUser({...user, process_roles: [...userProcessRoles, selectedProcessRole]})); 
            }
        }else{
            if ( typeof(user?.process_roles) !== "undefined") {
                if (user.process_roles.length > 0) dispatch(setUser({...user, process_roles: user?.process_roles?.filter((process_role:ProcessRoleType) => process_role.name !== e.target.name)})); 
            }
        }
    }

    if (id && user.id ===0 ) return <LoaderTemporal/>;


    return <div className="container-wrap">
    <Grid container spacing={2}>
        <Grid item xs={12} sm={9} className="text-left">
            <h2 className="text-left">{viewMode || editMode ? `${ user.name ? user.name : user.username}` : "Nuevo usuario" }</h2>
        </Grid> 
        <Grid item xs={12} sm={1} className="d-flex-center icon-btn">
        </Grid>
        <Grid item xs={12} sm={2} className="text-right">
            {canView_user && <Button className="btn btn-primary" onClick={() => navigate('/usuarios')}>Ir a Usuarios</Button>}
        </Grid>
    </Grid> 
    <div className="form-container">
        <Grid container spacing={4}>
            <Grid item xs={12} sm={4} className="text-left">
                {viewMode ? 
                 <div className="form-group">
                    <label>Nombre</label>
                    <p>{user.name ? user.name : "No registra"}</p>
                </div> :
                <div className="form-group">
                    <label>Nombres</label>
                    <TextField className='form-control' type="text" value={user.first_name} onChange={(e) => dispatch(setUser({...user, first_name: e.target.value}))} />
                </div>
                }
                {!viewMode ?
                <div className="form-group">
                    <label>Apellidos</label>
                    <TextField className='form-control' type="text" value={user.last_name} onChange={(e) => dispatch(setUser({...user, last_name: e.target.value}))} />
                </div> : null }

                {viewMode || editMode ?
                <div className="form-group">
                    <label>Usuario</label>
                    <p>{user.username}</p>
                </div> :
                <div className="form-group">
                    <label>Usuario</label>
                    <TextField className='form-control' type="text" value={user.username} onChange={(e) => dispatch(setUser({...user, username: e.target.value}))} />
                </div> }

                {!viewMode ?
                <div className="form-group">
                    <label>Contraseña {editMode ? <small>(Déjela en blanco si no desea cambiarla)</small> : null }</label>
                    <TextField className='form-control' type="password" value={user.password} onChange={(e) => dispatch(setUser({...user, password: e.target.value}))} />
                </div> : null }


                {viewMode ?
                <div className="form-group">
                    <label>Email</label>
                    <p>{user.email ?  user.email: "No registra"}</p>
                </div> :
                <div className="form-group">
                    <label>Email</label>
                    <TextField className='form-control' type="text" value={user.email} onChange={(e) => dispatch(setUser({...user, email: e.target.value}))} />
                </div> }

                {viewMode ?
                <div className="form-group">
                    <label>Estado</label>
                    <div>{user.status ? <Chip label='Activo' color='primary'/> : <Chip label='Inactivo' color='error'/> }</div>
                </div> :

                ( editMode? <div className="form-group">
                    <label>Activo</label>
                    <ToggleButtonGroup
                        value={user.status}
                        exclusive
                        onChange={(e, value) => dispatch(setUser({...user, status: value}))}
                        aria-label="text alignment"
                        color= "primary"
                    >
                        <ToggleButton value={true} aria-label="left aligned">
                            Si
                        </ToggleButton>
                        <ToggleButton value={false} aria-label="centered">
                            No
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div> : null )}

            </Grid>
            <Grid item xs={12} sm={4} className="text-left">

                {viewMode ? 
                <div className="form-group">
                    <label>Roles</label>
                    {user?.groups?.length === 0 ? <p>No registra</p> : null}
                    <p>{user?.groups?.map((role:GroupType) => role.name).join(', ')}</p>
                </div> :
                <div className="form-group">
                   <FormControl component="fieldset" variant="standard">
                     <FormLabel component="legend">Roles</FormLabel>
                        <FormGroup>   
                        {groups.map((role:GroupType, index:number) => <FormControlLabel
                            control={
                                <Switch checked={user?.groups?.find((group:GroupType) => group.id === role.id) ? true : false}
                                    onChange={(e) => handleChangeGroups(e)} 
                                    name={role.name} 
                                />}
                            label={role.name}
                            key={index}
                        />)}
                        </FormGroup>
                    </FormControl>
                </div> }
            </Grid>
            <Grid item xs={12} sm={4} className="text-left">

                {viewMode ?
                <div className="form-group">
                    <label>Roles de Procesos </label>
                    {user?.process_roles?.length === 0 ? <p>No registra</p> : null}
                    <p>{user?.process_roles?.map((role:ProcessRoleType) => role.name).join(', ')}</p>
                </div> :
                <div className="form-group">
                    <FormControl component="fieldset" variant="standard">
                        <FormLabel component="legend">Roles de Procesos</FormLabel>
                        <FormGroup>
                        {process_roles.map((role:ProcessRoleType, index:number) => <FormControlLabel
                            control={
                                <Switch checked={user?.process_roles?.find((process_role:ProcessRoleType) => process_role.id === role.id) ? true : false}
                                onChange={(e) => handleChangeProcessesRoles(e)}
                                     name={role.name} />}
                            label={role.name}
                            key={index}
                        />)}
                        </FormGroup>
                    </FormControl>
                </div> }


            </Grid>
        </Grid>
    </div>

    { viewMode? <Grid container spacing={2}>
        <Grid item xs={12} sm={12} className="text-right">
            <span className="text-log"><b>Fecha de Creación:</b> {user.date_joined? new Date(user.date_joined).toLocaleString('es-CO') : "No registra"}  </span> -
           <span className="text-log"><b>Último inicio de sesión:</b> {user.last_login? new Date(user.last_login).toLocaleString('es-CO') : "No registra"} </span>
        </Grid>
    </Grid>:null }

    <Grid container spacing={2}>
        <Grid item xs={12} sm={2} className="text-right">
            {!viewMode ? 
            <Button className="btn btn-primary" onClick={handleSave}>Guardar</Button>   
            : canChange_user ? <Button className="btn btn-primary" onClick={handleEditar}>Editar</Button> : null}
        </Grid>
    </Grid> 
    <br/>

</div>;


}

export default CreateEditUser;