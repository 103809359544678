import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { CustomerType } from '../types';

// Define a type for the slice state
interface CustomerState {
  customers: CustomerType[];
  customer: CustomerType;
}

// Define the initial state using that type
const initialState: CustomerState = {
  customers: [],
  customer: {
    id: 0,
    siigo_id: '',
    customer_name: '',
    customer_city: {
      id: 0,
      city_name: '',
      state_name: '',
      country_name: '',
    },
  },
};

export const customerSlice = createSlice({
  name: 'customer',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setCustomers: (state, action: PayloadAction<CustomerType[]>) => {
      state.customers = action.payload;
    },
    setCustomer: (state, action: PayloadAction<CustomerType>) => {
      state.customer = action.payload;
    },
  },
});

export const { setCustomers, setCustomer } = customerSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCustomer = (state: RootState) => state.customer;
export const selectCustomers = (state: RootState) => state.customer.customers;
export const initialStateCustomer = initialState;
export default customerSlice.reducer;
