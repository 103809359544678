import Grid from '@mui/material/Grid';
import { useAppContext } from '../Context';
const Home: React.FC = () => {
  const { auth } = useAppContext();
  return (
    <div className='container-wrap'>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={10} className='text-left'>
          <h2 className='text-left'>Hola, {auth.user.name ? auth.user.name : auth.user.first_name}</h2>
        </Grid>
        <Grid item xs={12} sm={2} className='text-right'></Grid>
      </Grid>
    </div>
  );
};

export default Home;
